import React from 'react';
import {
  Home,
  ShieldKey,
  CloudUpload,
  Magnify,
  AccountMultiple,
  ShieldLock,
  ClipboardText,
  Login,
  ExitToApp,
  Brain
} from 'mdi-material-ui';

export type AppSection = {
  title: string;
  titleComponent?: React.ReactNode;
  path: string;
  icon: React.ReactNode;
};

export const home: AppSection = {
  title: 'Home',
  path: '/',
  icon: <Home />,
};
export const requestData: AppSection = {
  title: 'Request credentials',
  path: '/request-credentials',
  icon: <ShieldKey />,
};
export const login: AppSection = {
  title: 'Login',
  path: '/login',
  icon: <Login />,
};
export const logout: AppSection = {
  title: 'Logout',
  path: '/logout',
  icon: <ExitToApp />,
};
export const shareData: AppSection = {
  title: 'Share your Data',
  path: '/share-data',
  icon: <CloudUpload />,
};
export const accessData: AppSection = {
  title: 'Access the Data',
  path: '/access-data',
  icon: <Magnify />,
};
export const aboutUs: AppSection = {
  title: 'About us',
  titleComponent: <span>About&nbsp;us</span>,
  path: '/about-us',
  icon: <AccountMultiple />,
};
export const privacyPolicy: AppSection = {
  title: 'Privacy Policy',
  path: '/privacy-policy',
  icon: <ShieldLock />,
};
export const termsConditions: AppSection = {
  title: 'Terms and Conditions',
  path: '/terms-conditions',
  icon: <ClipboardText />,
};

export const runAI: AppSection = {
  title: 'Run AI prediction',
  path: '/run-ai',
  icon: <Brain />,
};

export const mainSectionList = [requestData, shareData, accessData, runAI, aboutUs];
export const menuSectionList = [
  requestData,
  shareData,
  accessData,
  null,
  aboutUs,
  privacyPolicy,
  termsConditions,
  null,
  login,
];
export const footerSectionList = [privacyPolicy, termsConditions, login, aboutUs];
