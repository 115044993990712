import React, { useMemo, useState } from 'react';
import { Alert, Button, Card, CardContent, CircularProgress, styled, Typography } from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import { MenuRight as ExpandIcon, MenuDown as CollapseIcon } from 'mdi-material-ui';

import { S3File, TreeFile, TreeDirectory, PathNodeType } from '../../entities';
import { useUserFileListResource } from '../../hooks/store/useUserFile';
import { shareData } from '../../sections';
import { Layout, Spacer } from '../../components';
import UITreeDirectory from './UITreeDirectory';
import UploadDialog from './UploadDialog';
import { useCurrentUserGroupsResource } from '../../hooks/store/useAuth';

const CardTitle = styled('h2')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'baseline',
}));

function createFileTree(files: S3File[]) {
  const root = {
    name: '/',
    type: PathNodeType.DIR,
    children: {},
  } as TreeDirectory;
  files.forEach((file) => {
    const pathComponents = file.key.split('/');
    let currentDir = root;
    pathComponents.forEach((node, index) => {
      if (index < pathComponents.length - 1) {
        // Directory
        currentDir.children[node] =
          currentDir.children[node] ||
          ({
            name: node,
            type: PathNodeType.DIR,
            children: {},
          } as TreeDirectory);
        currentDir = currentDir.children[node] as TreeDirectory;
      } else {
        // File
        currentDir.children[node] = {
          name: node,
          type: PathNodeType.FILE,
          s3: file,
        } as TreeFile;
      }
    });
  });

  return root;
}

function FilesTree() {
  const [expandedNodes, setExpandedNodes] = useState([] as string[]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [userFileList, userFilesRequest] = useUserFileListResource();
  const userFileTree = useMemo(() => createFileTree(userFileList), [userFileList]);
  const [groups, groupsRequest] = useCurrentUserGroupsResource();

  const canUpload = useMemo(() => {
    if (!groups || groups.length === 0) {
      return false;
    }
    let found = false;
    for (let i = 0; !found && i < groups.length; i++) {
      found = groups[i] === 'Admin' || groups[i] === 'UserUpload';
    }
    return found;
  }, [groups]);

  return (
    <Layout title={shareData.title}>
      <Card>
        <CardContent>
          {groupsRequest.error ? (
            <Alert severity="error">{groupsRequest.error.message}</Alert>
          ) : groupsRequest.inProgress ? (
            <CircularProgress />
          ) : !canUpload ? (
            <Alert severity="info">
              Thank you for deciding to share your data!
              <br />
              <br />
              To receive all the upload instructions, please contact the AIforCOVID staff at{' '}
              <a href="mailto:aiforcovid@cdi.it">aiforcovid@cdi.it</a>
            </Alert>
          ) : (
            <>
              <Typography variant="h5" component={CardTitle}>
                <span>Queued files</span>
                <Spacer />
                <Button variant="contained" color="primary" onClick={() => setUploadDialogOpen(true)}>
                  Upload files
                </Button>
              </Typography>
              {userFilesRequest.inProgress ? (
                <CircularProgress />
              ) : userFileList.length === 0 ? (
                <Alert severity="info">
                  No file is being processed, please upload new files to start processing them.
                </Alert>
              ) : (
                <TreeView
                  defaultCollapseIcon={<CollapseIcon />}
                  defaultExpandIcon={<ExpandIcon />}
                  onNodeToggle={(_, nodes) => setExpandedNodes(nodes)}
                  expanded={expandedNodes}
                >
                  {Object.values(userFileTree.children).map((directory, index) => (
                    <UITreeDirectory key={index} directory={directory as TreeDirectory} />
                  ))}
                </TreeView>
              )}
            </>
          )}
          {uploadDialogOpen && <UploadDialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} />}
        </CardContent>
      </Card>
    </Layout>
  );
}

export default FilesTree;
