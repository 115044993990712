import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as YouTubeIcon } from './youtube.svg';
import { ReactComponent as LinkedInIcon } from './linkedin.svg';

export type SocialLink = {
  url: string;
  title: string;
  icon: React.ReactNode;
};

export const facebook: SocialLink = {
  url: 'https://www.facebook.com/CDIsalute/',
  title: 'Facebook',
  icon: <SvgIcon component={FacebookIcon} />,
};
export const twitter: SocialLink = {
  url: 'https://twitter.com/CDImilano',
  title: 'Twitter',
  icon: <SvgIcon component={TwitterIcon} />,
};
export const youtube: SocialLink = {
  url: 'https://www.youtube.com/user/centrodiagnosticoita',
  title: 'YouTube',
  icon: <SvgIcon component={YouTubeIcon} />,
};
export const instagram: SocialLink = {
  url: 'https://www.instagram.com/cdi.eventi/',
  title: 'Instagram',
  icon: <SvgIcon component={InstagramIcon} />,
};
export const linkedin: SocialLink = {
  url: 'https://it.linkedin.com/company/cdi-centro-diagnostico-italiano',
  title: 'LinkedIn',
  icon: <SvgIcon component={LinkedInIcon} />,
};

export const socialLinks: SocialLink[] = [facebook, twitter, youtube, instagram, linkedin];
