import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

import { Layout, Paragraph } from '../components';
import { termsConditions } from '../sections';

function TermsConditions() {
  return (
    <Layout title={termsConditions.title}>
      <Card>
        <CardContent>
          <Paragraph>Any user accessing AIforCOVID imaging archive data must agree to:</Paragraph>
          <Typography component="ul">
            <li>
              Not attempt to identify individual human research participants from whom the data were obtained, and
              follow all other conditions specified in our Site Disclaimer
            </li>
            <li>
              Acknowledge in all oral or written presentations, disclosures, or publications the specific dataset(s) or
              applicable accession number(s) through which the investigator accessed any data. See the Reference
              section.
            </li>
            <li>
              Registering on our portal and downloading the data you accept the User Registration Privacy Act
              Notification Statement
            </li>
          </Typography>
          <Paragraph>
            Data are freely available to browse, download, and use for commercial, scientific and educational purposes
            as outlined in the Creative Commons Attribution 3.0 Unported License.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Site Disclaimer
          </Typography>
          <Paragraph>
            CDI – Centro Diagnostico Italiano S.p.A. offers this image repository to encourage cross-disciplinary
            science. Some of this data may already be published or in public domain.
            <br />
            You may browse, download, and use the data for commercial, scientific and educational purposes.
            <br />
            Every effort will have been made to remove private information from each data both by the submitter,
            nonetheless ethical principles command all users to make no attempt to identify individuals from whatever
            data elements and metadata remain.
            <br />
            Requesting the download of data collected in this archive, you accept the conditions above.
            <br />
          </Paragraph>
          <Typography variant="h5" component="h3">
            Database description
          </Typography>
          <Paragraph>
            AIforCOVID imaging archive contained a total of 823 chest x-ray belonging to symptomatic COVID-19 patients.
            These examinations were performed at the time of hospitalization and come from eight Italian hospitals. All
            imaging and clinical data were retrospectively reviewed and collected after anonymization.
          </Paragraph>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default TermsConditions;
