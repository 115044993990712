import { combineReducers } from 'redux';
import auth from './auth';
import images from './images';
import requestCredentials from './requestCredentials';
import settings from './settings';
import userFile from './userFile';

export const rootReducer = combineReducers({
  requestCredentials,
  auth,
  userFile,
  images,
  settings,
});

export type RootState = ReturnType<typeof rootReducer>;
