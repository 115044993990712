import React from 'react';
import { Helmet } from 'react-helmet';
import { styled } from '@material-ui/core/styles';
import { Container, ContainerProps, Toolbar } from '@material-ui/core';

import { Footer, Header } from '.';

const LayoutWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  height: '100%',
  overflow: 'auto',
  position: 'relative',
}));

const ContentWrapper = styled('main')(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'block',
  padding: theme.spacing(4, 0),
}));

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.h3,
  margin: theme.spacing(0, 0, 3),
}));

interface PublicLayoutProps extends Omit<ContainerProps, 'title'> {
  title?: React.ReactNode;
}

function PublicLayout(props: PublicLayoutProps) {
  const { title, children, maxWidth, className, ...otherProps } = props;

  return (
    <LayoutWrapper className={className}>
      <Helmet>
        <title>{title ? `${title} | ${process.env.REACT_APP_SITE_TITLE}` : process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>
      <Header maxWidth={maxWidth} />
      <Toolbar />
      <ContentWrapper className="ContentWrapper-root">
        <Container maxWidth={maxWidth} {...otherProps}>
          {title && <Title>{title}</Title>}
          {children}
        </Container>
      </ContentWrapper>
      <Footer maxWidth={maxWidth} />
    </LayoutWrapper>
  );
}

export default PublicLayout;
