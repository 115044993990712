import { useMemo } from 'react';

import { useImages } from '../../hooks/store/useImages';
import { ResultRow } from '../../entities';
import columns from './columns';

export default function useResultRows() {
  const { searchImagesResults } = useImages();

  const rows: ResultRow[] = useMemo(
    () =>
      searchImagesResults?.data.map((result) =>
        columns.reduce((row, column) => {
          let value = result[column.field];
          if (result[column.field] === null) {
            value = '–';
          } else if (column.type === 'Number') {
            // just because we can't trust BE data
            value = Number(String(value)).toFixed(column.precision);
          } else if (typeof column.type === 'object') {
            value = column.type[Number(value)];
          }
          return {
            ...row,
            [column.field]: value || '?',
          };
        }, {} as ResultRow)
      ) || [],
    [searchImagesResults]
  );

  return { rows };
}
