import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import prettyBytes from 'pretty-bytes';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import {
  Folder as FolderIcon,
  File as FileIcon,
  Delete as DeleteIcon,
  CloudDownload as DownloadIcon,
} from 'mdi-material-ui';
import { ProgressButton } from '@top-solution/mui-inputs';

import { Spacer } from '../../components';
import { TreeDirectory, TreeFile, PathNodeType } from '../../entities';
import Storage from '@aws-amplify/storage';
import { useUserFile } from '../../hooks/store/useUserFile';

type UITreeDirectoryProps = {
  directory: TreeDirectory;
};

const NodeName = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),

  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  '& .node-name': {
    display: 'flex',
    alignItems: 'flex-start',

    [theme.breakpoints.down('md')]: {
      '&.file': {
        marginLeft: theme.spacing(-4),
      },
    },

    '& svg': {
      marginRight: theme.spacing(1),
    },
  },

  '& .meta': {
    fontFamily: 'monospace',
    display: 'flex',

    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },

    '& .key': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.8em',
    },

    '& .value': {
      fontSize: '.9em',
    },
  },
}));

export default function UITreeDirectory(props: UITreeDirectoryProps) {
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null as null | TreeFile);
  const { deleteUserFile, deleteUserFileListRequest } = useUserFile();

  const downloadFile = useCallback((file: TreeFile) => {
    Storage.get(file.s3.key, { level: file.s3.level }).then((url: unknown) => {
      const link = document.createElement('a');
      link.setAttribute('href', url as string);
      link.click();
    });
  }, []);

  return (
    <TreeItem
      nodeId={props.directory.name}
      label={
        <NodeName>
          <span className="node-name dir">
            <FolderIcon />
            <span>{props.directory.name}</span>
          </span>
        </NodeName>
      }
    >
      {Object.values(props.directory.children).map((node, index) =>
        node.type === PathNodeType.DIR ? (
          <UITreeDirectory key={index} directory={node as TreeDirectory} />
        ) : (
          <TreeItem
            key={index}
            nodeId={`${(node as TreeFile).s3.key}`}
            label={
              <NodeName>
                <span className="node-name file">
                  <FileIcon />
                  <span>{node.name}</span>
                </span>
                <Spacer />
                <span className="meta">
                  <span className="last-update">
                    <span className="key">Last update</span>
                    <span className="value">{format((node as TreeFile).s3.lastModified, 'PPpp')}</span>
                  </span>
                  <span className="level">
                    <span className="key">Access</span>
                    <span className="value">{(node as TreeFile).s3.level}</span>
                  </span>
                  <span className="size">
                    <span className="key">Size</span>
                    <span className="value">{prettyBytes((node as TreeFile).s3.size)}</span>
                  </span>
                </span>
                <span className="actions">
                  <IconButton onClick={() => downloadFile(node as TreeFile)}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton onClick={() => setSelectedFileToDelete(node as TreeFile)}>
                    <DeleteIcon />
                  </IconButton>
                </span>
                {selectedFileToDelete && (
                  <Dialog open={true}>
                    <DialogTitle>{selectedFileToDelete.name}</DialogTitle>
                    <DialogContent>
                      <p>
                        The file will be removed from the cloud and it won’t be possible to restore it, are you sure?
                      </p>
                      {deleteUserFileListRequest.error && (
                        <Alert severity="error">Unexpected error, please try again later</Alert>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setSelectedFileToDelete(null)}>Cancel</Button>
                      <Spacer />
                      <ProgressButton
                        variant="contained"
                        color="primary"
                        inProgress={deleteUserFileListRequest.inProgress}
                        onClick={() => deleteUserFile(selectedFileToDelete.s3)}
                      >
                        Delete
                      </ProgressButton>
                    </DialogActions>
                  </Dialog>
                )}
              </NodeName>
            }
          />
        )
      )}
    </TreeItem>
  );
}
