import { RequestError } from '@top-solution/utils';
import { ColumnSort, SearchFilter, SearchResult } from '../../entities';

export const SEARCH_IMAGES_REQUEST = 'SEARCH_IMAGES_REQUEST';
export const SEARCH_IMAGES_SUCCESS = 'SEARCH_IMAGES_SUCCESS';
export const SEARCH_IMAGES_FAILURE = 'SEARCH_IMAGES_FAILURE';

export const DOWNLOAD_IMAGE_REQUEST = 'DOWNLOAD_IMAGE_REQUEST';
export const DOWNLOAD_IMAGE_SUCCESS = 'DOWNLOAD_IMAGE_SUCCESS';
export const DOWNLOAD_IMAGE_PROGRESS = 'DOWNLOAD_IMAGE_PROGRESS';
export const DOWNLOAD_IMAGE_FAILURE = 'DOWNLOAD_IMAGE_FAILURE';
export const DOWNLOAD_IMAGE_CLEAR = 'DOWNLOAD_IMAGE_CLEAR';

export const READ_THUMBNAIL_URL_REQUEST = 'READ_THUMBNAIL_URL_REQUEST';
export const READ_THUMBNAIL_URL_SUCCESS = 'READ_THUMBNAIL_URL_SUCCESS';
export const READ_THUMBNAIL_URL_FAILURE = 'READ_THUMBNAIL_URL_FAILURE';

type DownloadRequest = {
  inProgress: boolean;
  progress: number;
  error: null | RequestError;
  url: null | string;
};

type ThumbnailRequest = {
  inProgress: boolean;
  error: null | RequestError;
  url: null | string;
};

const initialState = {
  results: null as null | SearchResult,
  requests: {
    search: {
      page: null as null | number,
      pageSize: null as null | number,
      filters: null as null | SearchFilter[],
      sort: null as null | ColumnSort,
      inProgress: false,
      error: null as null | RequestError,
    },
    downloads: {} as Record<string, DownloadRequest>,
    thumbnails: {} as Record<string, ThumbnailRequest>,
  },
};
export type ImagesState = typeof initialState;

export interface SearchImagesRequestAction {
  type: typeof SEARCH_IMAGES_REQUEST;
  page: number;
  pageSize: number;
  filters: SearchFilter[];
  sort: ColumnSort | null;
}
export interface SearchImagesSuccessAction {
  type: typeof SEARCH_IMAGES_SUCCESS;
  results: SearchResult;
}
export interface SearchImagesFailureAction {
  type: typeof SEARCH_IMAGES_FAILURE;
  error: RequestError;
}

export interface DownloadImageRequestAction {
  type: typeof DOWNLOAD_IMAGE_REQUEST;
  sha256: string;
}
export interface DownloadImageSuccessAction {
  type: typeof DOWNLOAD_IMAGE_SUCCESS;
  sha256: string;
  url: string;
}
export interface DownloadImageProgressAction {
  type: typeof DOWNLOAD_IMAGE_PROGRESS;
  sha256: string;
  progress: number;
}
export interface DownloadImageFailureAction {
  type: typeof DOWNLOAD_IMAGE_FAILURE;
  sha256: string;
  error: RequestError;
}

export interface DownloadImageClearAction {
  type: typeof DOWNLOAD_IMAGE_CLEAR;
  sha256: string;
}

export interface ReadThumbnailUrlRequestAction {
  type: typeof READ_THUMBNAIL_URL_REQUEST;
  sha256: string;
}
export interface ReadThumbnailUrlSuccessAction {
  type: typeof READ_THUMBNAIL_URL_SUCCESS;
  sha256: string;
  url: string;
}
export interface ReadThumbnailUrlFailureAction {
  type: typeof READ_THUMBNAIL_URL_FAILURE;
  sha256: string;
  error: RequestError;
}

type ActionType =
  | SearchImagesRequestAction
  | SearchImagesSuccessAction
  | SearchImagesFailureAction
  | DownloadImageRequestAction
  | DownloadImageSuccessAction
  | DownloadImageProgressAction
  | DownloadImageFailureAction
  | DownloadImageClearAction
  | ReadThumbnailUrlRequestAction
  | ReadThumbnailUrlSuccessAction
  | ReadThumbnailUrlFailureAction;

export default function ImagesReducer(state = initialState, action: ActionType): ImagesState {
  switch (action.type) {
    case SEARCH_IMAGES_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          search: {
            ...state.requests.search,
            inProgress: true,
            page: action.page,
            pageSize: action.pageSize,
            filters: action.filters,
            sort: action.sort,
          },
        },
      };
    }
    case SEARCH_IMAGES_SUCCESS: {
      return {
        ...state,
        results: action.results,
        requests: {
          ...state.requests,
          search: {
            ...state.requests.search,
            inProgress: false,
            error: null,
          },
        },
      };
    }
    case SEARCH_IMAGES_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          search: {
            ...state.requests.search,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case DOWNLOAD_IMAGE_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          downloads: {
            ...state.requests.downloads,
            [action.sha256]: {
              inProgress: true,
              progress: 0,
              error: null,
              url: null,
            },
          },
        },
      };
    }
    case DOWNLOAD_IMAGE_PROGRESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          downloads: {
            ...state.requests.downloads,
            [action.sha256]: {
              ...state.requests.downloads[action.sha256],
              progress: action.progress,
            },
          },
        },
      };
    }
    case DOWNLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          downloads: {
            ...state.requests.downloads,
            [action.sha256]: {
              ...state.requests.downloads[action.sha256],
              inProgress: false,
              progress: 100,
              url: action.url,
            },
          },
        },
      };
    }
    case DOWNLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          downloads: {
            ...state.requests.downloads,
            [action.sha256]: {
              ...state.requests.downloads[action.sha256],
              inProgress: false,
              error: action.error,
            },
          },
        },
      };
    }
    case DOWNLOAD_IMAGE_CLEAR: {
      const downloads = { ...state.requests.downloads };
      delete downloads[action.sha256];
      return {
        ...state,
        requests: {
          ...state.requests,
          downloads,
        },
      };
    }
    case READ_THUMBNAIL_URL_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          thumbnails: {
            ...state.requests.thumbnails,
            [action.sha256]: {
              inProgress: true,
              error: null,
              url: null,
            },
          },
        },
      };
    }
    case READ_THUMBNAIL_URL_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          thumbnails: {
            ...state.requests.thumbnails,
            [action.sha256]: {
              ...state.requests.thumbnails[action.sha256],
              inProgress: false,
              url: action.url,
            },
          },
        },
      };
    }
    case READ_THUMBNAIL_URL_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          thumbnails: {
            ...state.requests.thumbnails,
            [action.sha256]: {
              ...state.requests.thumbnails[action.sha256],
              inProgress: false,
              error: action.error,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}
