import { styled } from '@material-ui/core';

const FormRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(-1),
  marginRight: theme.spacing(-1),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  '& > *': {
    flex: '1 0 0%',
    margin: theme.spacing(1),
  },
}));

export default FormRow;
