import React from 'react';

function CDICitation() {
  return (
    <>
      For any manuscript developed using data from AIforCOVID archive please cite the relevant collection citations (see
      below) as well as the following publication:
      <br />
      <em>
        “Soda P, et al. AIforCOVID: Predicting the clinical outcomes in patients with COVID-19 applying AI to chest-X-rays. An Italian multicentre
        study. Med Image Anal. 2021 Aug 28;74:102216. doi: 10.1016/j.media.2021.102216 (<a href="https://www.sciencedirect.com/science/article/pii/S1361841521002619">download here</a>)”
      </em>
      <br />
      Please contact us at <a href="mailto:aiforcovid@cdi.it">aiforcovid@cdi.it</a> so we can include your work on our
      Related Publications page. We also encourage researchers to tweet about their ICIA-related research with the hash
      tag
      <a href="https://twitter.com/hashtag/AIforCOVID" target="_blank" rel="noopener noreferrer">
        #AIforCOVID
      </a>{' '}
      and the tag to{' '}
      <a href="https://twitter.com/CDImilano" target="_blank" rel="noopener noreferrer">
        @CDIMilano
      </a>
      .
    </>
  );
}

export default CDICitation;
