import { HiddenColumns } from '../../entities';

export const SET_PAGE_SIZE = 'set_page_size';
export const SET_HIDDEN_COLUMNS = 'set_hidden_columns';

const LS_KEY_SETTINGS = 'ai4covid_settings';

export type SettingsState = {
  pageSize: number;
  hiddenColumns: HiddenColumns;
};

const savedState = localStorage.getItem(LS_KEY_SETTINGS);
const initialState: SettingsState = savedState
  ? JSON.parse(savedState)
  : {
      pageSize: 25,
      hiddenColumns: {
        Row_number: true,
        ImageFile: true,
        Hospital: true,
        Age: false,
        Sex: false,
        'Positivity at admission': false,
        Temp_C: false,
        DaysFever: false,
        Cough: false,
        DifficultyInBreathing: false,
        'Therapy_anti-inflammatory ': true,
        Therapy_Tocilizumab: true,
        Therapy_Anakinra: true,
        Therapy_hydroxychloroquine: true,
        Therapy_antiviral: true,
        Therapy_Eparine: true,
        WBC: false,
        RBC: true,
        CRP: false,
        Fibrinogen: false,
        Glucose: true,
        PCT: true,
        LDH: false,
        INR: false,
        'D-dimer': true,
        Ox_percentage: false,
        PaO2: true,
        SaO2: false,
        PaCO2: true,
        pH: false,
        CardiovascularDisease: false,
        IschemicHeartDisease: false,
        AtrialFibrillation: false,
        HeartFailure: false,
        Ictus: false,
        HighBloodPressure: false,
        Diabetes: false,
        Dementia: false,
        BPCO: false,
        Cancer: false,
        'Chronic Kidney disease': false,
        RespiratoryFailure: false,
        Obesity: false,
        Position: true,
        Prognosis: false,
        Death: false,
        sha256: true,
        file_path: true,
      },
    };

export interface SetPageSizeAction {
  type: typeof SET_PAGE_SIZE;
  pageSize: number;
}

export interface SetHiddenColumnsAction {
  type: typeof SET_HIDDEN_COLUMNS;
  hiddenColumns: HiddenColumns;
}

type ActionType = SetPageSizeAction | SetHiddenColumnsAction;

export default function userFileReducer(state = initialState, action: ActionType): SettingsState {
  let newState = state;
  switch (action.type) {
    case SET_PAGE_SIZE:
      newState = {
        ...state,
        pageSize: action.pageSize,
      };
      break;
    case SET_HIDDEN_COLUMNS:
      newState = {
        ...state,
        hiddenColumns: action.hiddenColumns,
      };
      break;
  }
  localStorage.setItem(LS_KEY_SETTINGS, JSON.stringify(newState));
  return newState;
}
