import React, { useState } from 'react';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

import { ColumnSort, ResultColumn } from '../../entities';
import columns from './columns';
import useResultRows from './useResultRows';
import { useSettings } from '../../hooks/store/useSettings';
import RowActions from './RowActions';
import PreviewLightbox from './PreviewLightbox';

const NoWrapCell = styled(TableCell)({
  whiteSpace: 'nowrap',
});

type DataTableProps = {
  sort: ColumnSort | null;
  setSort: (sort: ColumnSort) => void;
};

function DataTable(props: DataTableProps) {
  const { sort, setSort } = props;
  const [previewIndex, setPreviewIndex] = useState(null as null | number);
  const { hiddenColumns } = useSettings();
  const { rows } = useResultRows();

  const handleChangeSort = (column: ResultColumn) => {
    setSort({
      field: column.field,
      ascending: column.field === sort?.field && !sort?.ascending,
    });
  };

  return (
    <TableContainer>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {columns
              .filter((column) => !hiddenColumns[column.field])
              .map((column, index) => (
                <NoWrapCell key={column.field} {...column.headerCellProps} colSpan={index === 0 ? 2 : undefined}>
                  <TableSortLabel
                    active={column.field === sort?.field}
                    direction={sort?.ascending ? 'asc' : 'desc'}
                    onClick={() => handleChangeSort(column)}
                  >
                    {column.headerName}
                  </TableSortLabel>
                </NoWrapCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={row.sha256}>
              <TableCell padding="none">
                <RowActions row={row} onOpenImagePreviewClick={() => setPreviewIndex(index)} />
              </TableCell>
              {columns
                .filter((column) => !hiddenColumns[column.field])
                .map(({ field, rowCellProps }) => (
                  <NoWrapCell key={`${row.sha256}-${field}`} {...rowCellProps}>
                    {row[field]}
                  </NoWrapCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PreviewLightbox rows={rows} rowIndex={previewIndex} onChange={setPreviewIndex} />
    </TableContainer>
  );
}

export default DataTable;
