import { Storage } from '@aws-amplify/storage';
import { RequestState, useResource } from '@top-solution/utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { S3File, S3FileLevel } from '../../entities';
import { RootState } from '../../store/reducers';
import {
  DeleteUserFileListFailureAction,
  DeleteUserFileListRequestAction,
  DeleteUserFileListSuccessAction,
  DELETE_USER_FILE_LIST_FAILURE,
  DELETE_USER_FILE_LIST_REQUEST,
  DELETE_USER_FILE_LIST_SUCCESS,
  ReadUserFileListFailureAction,
  ReadUserFileListRequestAction,
  ReadUserFileListSuccessAction,
  READ_USER_FILE_LIST_FAILURE,
  READ_USER_FILE_LIST_REQUEST,
  READ_USER_FILE_LIST_SUCCESS,
  UploadUserFileListClearAction,
  UploadUserFileListFailureAction,
  UploadUserFileListProgressAction,
  UploadUserFileListRequestAction,
  UploadUserFileListSuccessAction,
  UPLOAD_USER_FILE_LIST_CLEAR,
  UPLOAD_USER_FILE_LIST_FAILURE,
  UPLOAD_USER_FILE_LIST_PROGRESS,
  UPLOAD_USER_FILE_LIST_REQUEST,
  UPLOAD_USER_FILE_LIST_SUCCESS,
} from '../../store/reducers/userFile';
import { useTracking } from '../useTracking';
import { useAuth } from './useAuth';

type ProgressUpdate = {
  total: number;
  loaded: number;
};

export function useUserFile() {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const { trackEvent } = useTracking();

  const userFileList = useSelector((state: RootState) => state.userFile.list);

  const readUserFileListRequest = useSelector((state: RootState) => state.userFile.requests.readList);
  const deleteUserFileListRequest = useSelector((state: RootState) => state.userFile.requests.delete);
  const uploadUserFileListRequest = useSelector((state: RootState) => state.userFile.requests.upload);

  const readUserFileList = useCallback(() => {
    dispatch<ReadUserFileListRequestAction>({ type: READ_USER_FILE_LIST_REQUEST });

    const read = async () => {
      try {
        const publicFiles = await Storage.list('', { level: S3FileLevel.PUBLIC });
        const protectedFiles = await Storage.list('', { level: S3FileLevel.PROTECTED });
        const privateFiles = await Storage.list('', { level: S3FileLevel.PRIVATE });

        const list = [
          ...publicFiles.map((file: S3File) => ({ ...file, level: S3FileLevel.PUBLIC })),
          ...protectedFiles.map((file: S3File) => ({ ...file, level: S3FileLevel.PROTECTED })),
          ...privateFiles.map((file: S3File) => ({ ...file, level: S3FileLevel.PRIVATE })),
        ];
        dispatch<ReadUserFileListSuccessAction>({ type: READ_USER_FILE_LIST_SUCCESS, list });
      } catch (error) {
        dispatch<ReadUserFileListFailureAction>({ type: READ_USER_FILE_LIST_FAILURE, error });
      }
    };
    return read();
  }, [dispatch]);

  const deleteUserFile = useCallback(
    (file: S3File) => {
      dispatch<DeleteUserFileListRequestAction>({ type: DELETE_USER_FILE_LIST_REQUEST, file });

      const deleteFile = async () => {
        try {
          await Storage.remove(file.key, { level: file.level });
          dispatch<DeleteUserFileListSuccessAction>({ type: DELETE_USER_FILE_LIST_SUCCESS });
          trackEvent('delete', 'user-file', file.key);
        } catch (error) {
          dispatch<DeleteUserFileListFailureAction>({ type: DELETE_USER_FILE_LIST_FAILURE, error });
        }
        await readUserFileList();
      };
      return deleteFile();
    },
    [dispatch, readUserFileList, trackEvent]
  );

  const uploadUserFileList = useCallback(
    (prefix: string, list: File[]) => {
      dispatch<UploadUserFileListRequestAction>({ type: UPLOAD_USER_FILE_LIST_REQUEST, list });

      const upload = async () => {
        try {
          await Promise.all(
            list.map(
              async (file) =>
                await Storage.put(`${currentUser?.username}/${prefix}/${file.name}`, file, {
                  serverSideEncryption: 'aws:kms',
                  SSEKMSKeyId: process.env.REACT_APP_KMS_KEY_ID,
                  level: 'private',
                  contentType: 'txt',
                  progressCallback: ({ loaded, total }: ProgressUpdate) => {
                    const progress = (loaded / total) * 100;
                    dispatch<UploadUserFileListProgressAction>({
                      type: UPLOAD_USER_FILE_LIST_PROGRESS,
                      filename: file.name,
                      progress,
                    });
                  },
                })
            )
          );
          dispatch<UploadUserFileListSuccessAction>({ type: UPLOAD_USER_FILE_LIST_SUCCESS });
          trackEvent('upload', 'user-file', undefined, list.length);
        } catch (error) {
          dispatch<UploadUserFileListFailureAction>({ type: UPLOAD_USER_FILE_LIST_FAILURE, error });
        }
        await readUserFileList();
      };
      return upload();
    },
    [currentUser?.username, dispatch, readUserFileList, trackEvent]
  );

  const uploadUserFileListClear = useCallback(() => {
    dispatch<UploadUserFileListClearAction>({ type: UPLOAD_USER_FILE_LIST_CLEAR });
  }, [dispatch]);

  return {
    userFileList,
    readUserFileList,
    readUserFileListRequest,
    deleteUserFile,
    deleteUserFileListRequest,
    uploadUserFileList,
    uploadUserFileListRequest,
    uploadUserFileListClear,
  };
}

export function useUserFileListResource() {
  const { userFileList, readUserFileList, readUserFileListRequest } = useUserFile();

  return useResource<typeof userFileList, RequestState>(
    'UserFileList',
    userFileList,
    readUserFileList,
    readUserFileListRequest
  );
}
