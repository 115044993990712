import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { login as loginSection, logout as logoutSection } from '../sections';
import { useCurrentUserResource } from '../hooks/store/useAuth';

function LoginButton() {
  const [currentUser] = useCurrentUserResource();
  const section = currentUser ? logoutSection : loginSection;

  return (
    <Button component={Link} to={section.path} startIcon={section.icon} color="inherit">
      {section.title}
    </Button>
  );
}

export default LoginButton;
