import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequestCredentialsRepository, { RequestCredentialsPayload } from '../../api/RequestCredentialsRepository';
import { RootState } from '../../store/reducers';
import {
  RequestCredentialsFailureAction,
  RequestCredentialsRequestAction,
  RequestCredentialsSuccessAction,
  REQUEST_CREDENTIALS_FAILURE,
  REQUEST_CREDENTIALS_REQUEST,
  REQUEST_CREDENTIALS_SUCCESS,
} from '../../store/reducers/requestCredentials';
import { useTracking } from '../useTracking';

export function useRequestCredentials() {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const createRequestCredentialsRequest = useSelector((state: RootState) => state.requestCredentials.requests.create);

  const createRequestCredentials = useCallback(
    (payload: RequestCredentialsPayload) => {
      dispatch<RequestCredentialsRequestAction>({ type: REQUEST_CREDENTIALS_REQUEST });
      const create = async () => {
        try {
          await RequestCredentialsRepository.create(payload);
          dispatch<RequestCredentialsSuccessAction>({ type: REQUEST_CREDENTIALS_SUCCESS });
          trackEvent('submit', 'request-data');
        } catch (error) {
          dispatch<RequestCredentialsFailureAction>({ type: REQUEST_CREDENTIALS_FAILURE, error });
        }
      };
      return create();
    },
    [dispatch, trackEvent]
  );

  return {
    createRequestCredentialsRequest,
    createRequestCredentials,
  };
}
