import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  DialogTitle,
  Box,
  Typography,
  DialogProps,
} from '@material-ui/core';

import { ResultColumn, SearchFilter, SearchOperator, searchOperator } from '../../entities';
import { Spacer } from '../../components';
import columns from './columns';

const operators = Object.values(searchOperator);

type AddFilterForm = {
  column?: ResultColumn;
  operator?: SearchOperator;
  value?: string;
};

const initialValues: AddFilterForm = {
  column: undefined,
  operator: undefined,
  value: '',
};

interface AddFilterDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: (filter?: SearchFilter) => void;
}

function AddFilterDialog(props: AddFilterDialogProps) {
  const { onClose, ...otherProps } = props;
  const [filter, setFilter] = useState(initialValues);

  const handleAddFilter = () => {
    if (filter && filter.column && filter.operator && filter.value) {
      onClose({
        column: filter.column,
        operator: filter.operator,
        value: filter.value,
      } as SearchFilter);
      setFilter(initialValues);
    }
  };

  const handleSelectColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const column = columns.find(({ field }) => field === event.target.value);
    let operator;
    switch (column?.type) {
      case 'String':
        operator = searchOperator.equals;
        break;
      case 'Number':
        operator = undefined;
        break;
      default:
        operator = searchOperator.exact;
        break;
    }
    setFilter({
      ...filter,
      column,
      operator,
    });
  };

  const handleSelectOperator = (event: React.ChangeEvent<HTMLInputElement>) => {
    const operator = operators.find(({ label }) => label === event.target.value);
    setFilter({
      ...filter,
      operator,
    });
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      value: event.target.value,
    });
  };

  return (
    <Dialog disableBackdropClick fullWidth maxWidth="sm" {...otherProps}>
      <DialogTitle>Add filter</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <TextField label="Field" value={filter.column?.field || ''} onChange={handleSelectColumn} select fullWidth>
            {columns.map((column) => (
              <MenuItem key={column.field} value={column.field}>
                {column.headerName}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ display: 'flex', mb: 2 }}>
          {!filter.column ? (
            <Typography>Please select a field</Typography>
          ) : filter.column.type === 'Number' ? (
            <>
              <TextField
                label="Operator"
                value={filter.operator?.label || ''}
                onChange={handleSelectOperator}
                style={{ minWidth: '13ch', marginRight: 8 }}
                select
              >
                {operators
                  .filter(({ display }) => display)
                  .map((operator) => (
                    <MenuItem key={operator.label} value={operator.label}>
                      {operator.label}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField label="value" value={filter.value} onChange={handleChangeValue} fullWidth />
            </>
          ) : filter.column.type === 'String' ? (
            <TextField label="Value" value={filter.value} onChange={handleChangeValue} fullWidth />
          ) : (
            <TextField label="Value" value={filter.value || ''} onChange={handleChangeValue} select fullWidth>
              {Object.keys(filter.column.type)
                .filter((key) => !isNaN(Number(key)))
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {filter.column?.type[Number(key)]}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Spacer />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!(filter.column && filter.operator && filter.value)}
          onClick={handleAddFilter}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddFilterDialog;
