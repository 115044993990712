import { RequestError } from '@top-solution/utils';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const READ_CURRENT_USER_REQUEST = 'READ_CURRENT_USER_REQUEST';
export const READ_CURRENT_USER_SUCCESS = 'READ_CURRENT_USER_SUCCESS';
export const READ_CURRENT_USER_FAILURE = 'READ_CURRENT_USER_FAILURE';
export const READ_CURRENT_USER_GROUPS_REQUEST = 'READ_CURRENT_USER_GROUPS_REQUEST';
export const READ_CURRENT_USER_GROUPS_SUCCESS = 'READ_CURRENT_USER_GROUPS_SUCCESS';
export const READ_CURRENT_USER_GROUPS_FAILURE = 'READ_CURRENT_USER_GROUPS_FAILURE';

type UserInfo = {
  username: string;
  id: string;
  attributes: {
    email: string;
    email_verified: boolean;
    sub: string;
  };
};

const initialState = {
  currentUser: null as null | UserInfo,
  currentUserGroups: null as null | string[],
  requests: {
    login: {
      inProgress: false as boolean,
      error: null as null | RequestError,
    },
    read: {
      inProgress: false as boolean,
      error: null as null | RequestError,
      lastUpdate: null as null | Date,
    },
    readGroups: {
      inProgress: false as boolean,
      error: null as null | RequestError,
      lastUpdate: null as null | Date,
    },
  },
};
export type LoginState = typeof initialState;

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}
export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  userInfo: UserInfo;
}
export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  error: RequestError;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface ReadCurrentUserRequestAction {
  type: typeof READ_CURRENT_USER_REQUEST;
}
export interface ReadCurrentUserSuccessAction {
  type: typeof READ_CURRENT_USER_SUCCESS;
  userInfo: UserInfo;
}
export interface ReadCurrentUserFailureAction {
  type: typeof READ_CURRENT_USER_FAILURE;
  error: RequestError;
}

export interface ReadCurrentUserGroupsRequestAction {
  type: typeof READ_CURRENT_USER_GROUPS_REQUEST;
}
export interface ReadCurrentUserGroupsSuccessAction {
  type: typeof READ_CURRENT_USER_GROUPS_SUCCESS;
  groups: string[];
}
export interface ReadCurrentUserGroupsFailureAction {
  type: typeof READ_CURRENT_USER_GROUPS_FAILURE;
  error: RequestError;
}

type ActionType =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | ReadCurrentUserRequestAction
  | ReadCurrentUserSuccessAction
  | ReadCurrentUserFailureAction
  | ReadCurrentUserGroupsRequestAction
  | ReadCurrentUserGroupsSuccessAction
  | ReadCurrentUserGroupsFailureAction;

export default function LoginReducer(state = initialState, action: ActionType): LoginState {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          login: {
            ...state.requests.login,
            inProgress: true,
          },
        },
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        currentUser: action.userInfo,
        requests: {
          ...state.requests,
          login: {
            ...state.requests.login,
            inProgress: false,
            error: null,
          },
        },
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          login: {
            ...state.requests.login,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case LOGOUT: {
      return {
        ...state,
        currentUser: null,
      };
    }
    case READ_CURRENT_USER_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          read: {
            ...state.requests.read,
            inProgress: true,
          },
        },
      };
    case READ_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        currentUser: action.userInfo,
        requests: {
          ...state.requests,
          read: {
            ...state.requests.read,
            inProgress: false,
            error: null,
            lastUpdate: new Date(),
          },
        },
      };
    }
    case READ_CURRENT_USER_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          read: {
            ...state.requests.read,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case READ_CURRENT_USER_GROUPS_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readGroups: {
            ...state.requests.readGroups,
            inProgress: true,
          },
        },
      };
    case READ_CURRENT_USER_GROUPS_SUCCESS: {
      return {
        ...state,
        currentUserGroups: action.groups,
        requests: {
          ...state.requests,
          readGroups: {
            ...state.requests.readGroups,
            inProgress: false,
            error: null,
            lastUpdate: new Date(),
          },
        },
      };
    }
    case READ_CURRENT_USER_GROUPS_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readGroups: {
            ...state.requests.readGroups,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
