import React from 'react';

export type TrackingContextConfig = {
  trackingId?: string;
};

export const TrackingContext = React.createContext({} as TrackingContextConfig);

export type TrackingContextProviderProps = {
  children: Array<React.ReactNode> | React.ReactNode;
  config: TrackingContextConfig;
};

export function TrackingContextProvider({ config, children }: TrackingContextProviderProps): JSX.Element {
  return <TrackingContext.Provider value={config}>{children}</TrackingContext.Provider>;
}
