import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled } from '@material-ui/core/styles';
import { Button, Container, Typography } from '@material-ui/core';

import { Footer } from '../../components';
import { mainSectionList } from '../../sections';
import logo from '../../logo/negative.svg';
import logoBracco from '../../logo/logobracco.svg';
//import logoCdi from '../../logo/logocdi.svg';
import background from './background.jpg';

const HomeWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  height: '100%',
  overflow: 'auto',
}));

const MainVieport = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  backgroundImage: `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: theme.palette.getContrastText(theme.palette.common.black),
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    height: '100%',
  },

  '& h2': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
}));

const Header = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxHeight: theme.typography.fontSize * 5,
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: theme.typography.fontSize * 8,
  },
}));

// const LogoCdi = styled('img')(({ theme }) => ({
//   [theme.breakpoints.down('sm')]: {
//     maxHeight: theme.typography.fontSize * 5,
//   },
//   [theme.breakpoints.up('sm')]: {
//     maxHeight: theme.typography.fontSize * 8,
//   },
// }));

const LogoBracco = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxHeight: theme.typography.fontSize * 5,
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: theme.typography.fontSize * 8,
  },
}));


const TextWrapper = styled(Container)(({ theme }) => ({
  background: 'rgba(0, 0, 0, .4)',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  width: '100%',
  maxWidth: theme.breakpoints.values.lg,

  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },

  '& p': {
    margin: theme.spacing(1, 0),
  },

  '& a': {
    color: 'inherit',
  },
}));

const NavLinks = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

  '& a': {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
}));

function Home() {
  return (
    <HomeWrapper>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>
      <MainVieport>
        <Header maxWidth={false}>
          <Logo src={logo} alt="Centro Diagnostico Italiano" />
          <div></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <LogoBracco src={logoBracco} alt="Bracco Imaging" style={{
            position: 'absolute',
            right: 20,
            top: 20
          }} />

        </Header>
        <TextWrapper maxWidth={false}>
          <Typography variant="h3" component="h1">
            Welcome to the AIforCOVID imaging archive
          </Typography>
          <Typography variant="subtitle1" component="p">
            The AIforCOVID imaging archive hosts a large archive of medical images of Italian COVID-19 patients. This
            project was promoted by CDI Centro Diagnostico Italiano (Milan) with Bracco Imaging S.p.A. (Milan), and in partnership with Fondazione IRCCS Ca’
            Granda Ospedale Maggiore Policlinico (Milan), Fondazione IRCCS Policlinico San Matteo (Pavia), Azienda
            ospedaliero-universitaria Careggi (Florence), ASST Santi Paolo e Carlo (Milan), ASST Fatebenefratelli-Sacco
            (Milan), ASST Ospedale San Gerardo (Monza), Ospedale Casa Sollievo della Sofferenza (San Giovanni Rotondo),
            Università Campus Bio-Medico (Roma), and Istituto Italiano di Tecnologia (Genova).
          </Typography>
          {/* prettier-ignore */}
          <Typography variant="subtitle1" component="p">
            <strong>Please cite this reference if you use our data:</strong> “Soda P, et al. AIforCOVID: Predicting the clinical outcomes in patients with COVID-19 applying AI to chest-X-rays. An Italian multicentre
            study. Med Image Anal. 2021 Aug 28;74:102216. doi: 10.1016/j.media.2021.102216 (<a href="https://www.sciencedirect.com/science/article/pii/S1361841521002619">download here</a>)”
          </Typography>
          {/* <Typography variant="subtitle2" component="p">
            Work reference:{' '}
            <a href="http://xxxxxxxxxxxxx" target="_blank" rel="noopener noreferrer">
              http://xxxxxxxxxxxxx
            </a>
          </Typography> */}
          <NavLinks>
            {mainSectionList.map(({ title, titleComponent, path, icon }) => (
              <Button component={Link} key={path} to={path} startIcon={icon} variant="outlined" color="inherit">
                {titleComponent || title}
              </Button>
            ))}
          </NavLinks>
        </TextWrapper>
      </MainVieport>
      <Footer maxWidth={false} />
    </HomeWrapper>
  );
}

export default Home;
