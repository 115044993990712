import { RequestError } from '@top-solution/utils';

export const REQUEST_CREDENTIALS_REQUEST = 'REQUEST_CREDENTIALS_REQUEST';
export const REQUEST_CREDENTIALS_SUCCESS = 'REQUEST_CREDENTIALS_SUCCESS';
export const REQUEST_CREDENTIALS_FAILURE = 'REQUEST_CREDENTIALS_FAILURE';

const initialState = {
  requests: {
    create: {
      success: false as boolean,
      inProgress: false as boolean,
      error: null as null | RequestError,
    },
  },
};
export type RequestCredentialsState = typeof initialState;

export interface RequestCredentialsRequestAction {
  type: typeof REQUEST_CREDENTIALS_REQUEST;
}
export interface RequestCredentialsSuccessAction {
  type: typeof REQUEST_CREDENTIALS_SUCCESS;
}
export interface RequestCredentialsFailureAction {
  type: typeof REQUEST_CREDENTIALS_FAILURE;
  error: RequestError;
}

type ActionType = RequestCredentialsRequestAction | RequestCredentialsSuccessAction | RequestCredentialsFailureAction;

export default function RequestCredentialsReducer(state = initialState, action: ActionType): RequestCredentialsState {
  switch (action.type) {
    case REQUEST_CREDENTIALS_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            success: false,
            inProgress: true,
          },
        },
      };
    }
    case REQUEST_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            success: true,
            inProgress: false,
            error: null,
          },
        },
      };
    }
    case REQUEST_CREDENTIALS_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
