import React, { useEffect } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import { useTracking } from './hooks/useTracking';
import { AuthenticatedRoute } from './components';
import {
  home,
  aboutUs,
  privacyPolicy,
  termsConditions,
  requestData,
  login,
  logout,
  shareData,
  accessData
} from './sections';
import {
  Home,
  AboutUs,
  PrivacyPolicy,
  TermsConditions,
  RequestCredentials,
  Login,
  Logout,
  ShareData,
  AccessData
} from './pages';

function App() {
  const { trackPageview } = useTracking();
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageview(pathname);
  }, [pathname, trackPageview]);

  return (
    <Switch>
      <Route exact path={home.path} component={Home} />
      <Route exact path={aboutUs.path} component={AboutUs} />
      <Route exact path={privacyPolicy.path} component={PrivacyPolicy} />
      <Route exact path={termsConditions.path} component={TermsConditions} />
      <Route exact path={requestData.path} component={RequestCredentials} />
      <Route exact path={login.path} component={Login} />
      <Route exact path={logout.path} component={Logout} />
      <Route exact path={logout.path} component={Logout} />
      <Route path='/run-ai' component={() => {
        window.open(
          process.env.REACT_APP_RUNAI,
          '_blank'
        );

        window.history.replaceState(null, "", "/");
        window.location.href="/";

        return null;
      }} />
      <AuthenticatedRoute exact path={shareData.path} component={ShareData} />
      <AuthenticatedRoute exact path={accessData.path} component={AccessData} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

export default App;
