export const searchOperator = {
  equals: { type: 'match', label: '=', verb: 'query', display: true },
  exact: { type: 'term', label: '≡', verb: 'value', display: false },
  gt: { type: 'range', label: '>', verb: 'gt', display: true },
  lt: { type: 'range', label: '<', verb: 'lt', display: true },
  gte: { type: 'range', label: '≥', verb: 'gte', display: true },
  lte: { type: 'range', label: '≤', verb: 'lte', display: true },
};

export type SearchOperator = {
  type: string;
  label: string;
  verb: string;
  display: boolean;
};
