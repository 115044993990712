const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AUTH_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_STORAGE_BUCKET,
      region: process.env.REACT_APP_STORAGE_REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: 'APISearchGateway',
        endpoint: process.env.REACT_APP_API_GATEWAY,
      },
    ],
  },
};

export default amplifyConfig;
