import { useCallback, useContext, useEffect } from 'react';
import { installGtag, gtag } from 'mr-gtag';
import { TrackingContext } from '../TrackingContextProvider';

export function useTracking(): {
  trackEvent: (
    action: string,
    category?: string | undefined,
    label?: string | undefined,
    value?: number | undefined
  ) => void;
  trackPageview: (path?: string | undefined, location?: string | undefined, title?: string | undefined) => void;
} {
  const { trackingId } = useContext(TrackingContext);

  const trackEvent = useCallback(
    (action: string, category?: string, label?: string, value?: number) => {
      if (trackingId) {
        gtag('event', action, {
          event_category: category,
          event_label: label,
          value: value,
        });
      }
    },
    [trackingId]
  );

  const trackPageview = useCallback(
    (path?: string, location?: string, title?: string) => {
      if (trackingId) {
        gtag('event', 'page_view', {
          page_path: path || window.location.pathname,
          page_location: location || window.location.href,
          page_title: title || document.title,
        });
      }
    },
    [trackingId]
  );

  useEffect(() => {
    if (trackingId) {
      installGtag(trackingId, { sendDefaultPageView: false });
    } else {
      // eslint-disable-next-line no-console
      console.warn('Tracking not enabled, as `trackingId` was not configured.');
    }
  }, [trackingId]);

  return { trackEvent, trackPageview };
}
