import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { home } from '../sections';
import { useAuth } from '../hooks/store/useAuth';
import { Layout } from '../components';
import { CircularProgress } from '@material-ui/core';

function Logout() {
  const { logout, currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      logout();
    }
  }, [currentUser, logout]);

  if (!currentUser) {
    return <Redirect to={home.path} />;
  }
  return (
    <Layout>
      <CircularProgress />
    </Layout>
  );
}

export default Logout;
