import React, { useMemo, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Button,
  Container,
  ContainerProps,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { Menu as MenuIcon, Account as UserIcon, ExitToApp as LogoutIcon } from 'mdi-material-ui';

import logo from '../logo/small.svg';
import { logout, mainSectionList } from '../sections';
import { Spacer } from '.';
import { MenuDrawer } from './MenuDrawer';
import { useCurrentUserResource } from '../hooks/store/useAuth';

const ToolbarContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
});

const Logo = styled(NavLink)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
  '& img': {
    height: 2 * theme.typography.fontSize,
  },
}));

const HeaderLink = styled(NavLink)(({ theme }) => ({
  ...theme.mixins.toolbar,
  borderRadius: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  '&.active': {
    background: theme.palette.secondary.main,
  },
}));

function Header(props: ContainerProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null as null | HTMLElement);

  const [currentUser] = useCurrentUserResource();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initials = useMemo(
    () =>
      currentUser?.username
        .split('.')
        .slice(0, 2)
        .map((slice) => slice[0])
        .join('')
        .toUpperCase(),
    [currentUser]
  );

  return (
    <AppBar>
      <Toolbar disableGutters>
        <ToolbarContainer {...props}>
          <Button component={Logo} to="/" color="inherit">
            <img src={logo} alt="" />
          </Button>
          <Spacer />

          {!isMobile && (
            <>
              {mainSectionList.map(({ path, title, titleComponent }) => (
                <Button key={path} component={HeaderLink} to={path} color="inherit" activeClassName="active">
                  {titleComponent || title}
                </Button>
              ))}
              {initials && (
                <>
                  <IconButton onClick={({ currentTarget }) => setUserMenuAnchorEl(currentTarget)}>
                    <Avatar>{initials}</Avatar>
                  </IconButton>
                  <Menu
                    open={Boolean(userMenuAnchorEl)}
                    onClose={() => setUserMenuAnchorEl(null)}
                    anchorEl={userMenuAnchorEl}
                    keepMounted
                  >
                    <MenuItem disabled>
                      <ListItemIcon>
                        <UserIcon />
                      </ListItemIcon>
                      {currentUser?.username}
                    </MenuItem>
                    <Divider />
                    <MenuItem component={Link} to={logout.path}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              )}
            </>
          )}
          {isMobile && (
            <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </ToolbarContainer>
      </Toolbar>
      <MenuDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
    </AppBar>
  );
}

export default Header;
