import auth from '@aws-amplify/auth';
import { RequestState, useResource } from '@top-solution/utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import {
  LoginFailureAction,
  LoginRequestAction,
  LoginSuccessAction,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  LogoutAction,
  ReadCurrentUserFailureAction,
  ReadCurrentUserGroupsFailureAction,
  ReadCurrentUserGroupsRequestAction,
  ReadCurrentUserGroupsSuccessAction,
  ReadCurrentUserRequestAction,
  ReadCurrentUserSuccessAction,
  READ_CURRENT_USER_FAILURE,
  READ_CURRENT_USER_GROUPS_FAILURE,
  READ_CURRENT_USER_GROUPS_REQUEST,
  READ_CURRENT_USER_GROUPS_SUCCESS,
  READ_CURRENT_USER_REQUEST,
  READ_CURRENT_USER_SUCCESS,
} from '../../store/reducers/auth';
import { useTracking } from '../useTracking';

export function useAuth() {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const currentUserGroups = useSelector((state: RootState) => state.auth.currentUserGroups);
  const readCurrentUserRequest = useSelector((state: RootState) => state.auth.requests.read);
  const readCurrentUserGroupsRequest = useSelector((state: RootState) => state.auth.requests.readGroups);
  const loginRequest = useSelector((state: RootState) => state.auth.requests.login);

  const login = useCallback(
    ({ username, password }) => {
      dispatch<LoginRequestAction>({ type: LOGIN_REQUEST });
      const login = async () => {
        try {
          await auth.signIn(username, password);
          const userInfo = await auth.currentUserInfo();
          trackEvent('login', username);
          dispatch<LoginSuccessAction>({ type: LOGIN_SUCCESS, userInfo });
        } catch (error) {
          dispatch<LoginFailureAction>({ type: LOGIN_FAILURE, error });
        }
      };
      return login();
    },
    [dispatch, trackEvent]
  );

  const logout = useCallback(() => {
    const logout = async () => {
      await auth.signOut();
      dispatch<LogoutAction>({ type: LOGOUT });
      trackEvent('logout', currentUser?.username);
    };
    return logout();
  }, [currentUser, dispatch, trackEvent]);

  const readCurrentUser = useCallback(() => {
    dispatch<ReadCurrentUserRequestAction>({ type: READ_CURRENT_USER_REQUEST });
    const read = async () => {
      try {
        const userInfo = await auth.currentAuthenticatedUser();
        dispatch<ReadCurrentUserSuccessAction>({ type: READ_CURRENT_USER_SUCCESS, userInfo });
        return userInfo;
      } catch (error) {
        dispatch<ReadCurrentUserFailureAction>({ type: READ_CURRENT_USER_FAILURE, error });
      }
    };
    return read();
  }, [dispatch]);

  const readCurrentUserGroups = useCallback(() => {
    dispatch<ReadCurrentUserGroupsRequestAction>({ type: READ_CURRENT_USER_GROUPS_REQUEST });
    const read = async () => {
      try {
        const session = await auth.currentSession();
        const groups = session.getAccessToken().payload['cognito:groups'];
        dispatch<ReadCurrentUserGroupsSuccessAction>({ type: READ_CURRENT_USER_GROUPS_SUCCESS, groups });
        return groups;
      } catch (error) {
        dispatch<ReadCurrentUserGroupsFailureAction>({ type: READ_CURRENT_USER_GROUPS_FAILURE, error });
      }
    };
    return read();
  }, [dispatch]);

  return {
    currentUser,
    currentUserGroups,
    readCurrentUser,
    readCurrentUserRequest,
    readCurrentUserGroups,
    readCurrentUserGroupsRequest,
    login,
    loginRequest,
    logout,
  };
}

export function useCurrentUserResource() {
  const { currentUser, readCurrentUser, readCurrentUserRequest } = useAuth();
  return useResource<typeof currentUser, RequestState>(
    'CurrentUser',
    currentUser,
    readCurrentUser,
    readCurrentUserRequest
  );
}

export function useCurrentUserGroupsResource() {
  const { currentUserGroups, readCurrentUserGroups, readCurrentUserGroupsRequest } = useAuth();
  return useResource<typeof currentUserGroups, RequestState>(
    'CurrentUserGroups',
    currentUserGroups,
    readCurrentUserGroups,
    readCurrentUserGroupsRequest
  );
}
