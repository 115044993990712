import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Alert, AlertTitle, Box, Card, CardContent, styled } from '@material-ui/core';
import { useForm } from '@top-solution/use-form';
import { CheckBox, TextField, ProgressButton } from '@top-solution/mui-inputs';

import { Layout, FormRow, Paragraph, CDICitation } from '../components';
import { requestData, privacyPolicy, termsConditions } from '../sections';
import { useRequestCredentials } from '../hooks/store/useRequestCredentials';

const SubmitWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

type RequestDataForm = {
  name: string;
  middle_name: string;
  surname: string;
  email: string;
  institutional_affiliation: string;
  privacy_policy: boolean;
  terms_conditions: boolean;
  cdi_citation: boolean;
};

const schema = yup.object().shape({
  name: yup.string().required().min(2),
  middle_name: yup.string(),
  surname: yup.string().required().min(2),
  email: yup.string().email().required(),
  institutional_affiliation: yup.string().required().min(2),
  privacy_policy: yup.boolean().oneOf([true]).required(),
  terms_conditions: yup.boolean().oneOf([true]).required(),
  cdi_citation: yup.boolean().oneOf([true]).required(),
});

const initialValues = {
  name: '',
  middle_name: '',
  surname: '',
  email: '',
  institutional_affiliation: '',
  privacy_policy: false,
  terms_conditions: false,
  cdi_citation: false,
};

function RequestCredentials() {
  const { createRequestCredentialsRequest, createRequestCredentials } = useRequestCredentials();

  const handleSubmit = useCallback(
    async ({ name, middle_name, surname, email, institutional_affiliation }: RequestDataForm) => {
      createRequestCredentials({ name, middle_name, surname, email, institutional_affiliation });
    },
    [createRequestCredentials]
  );

  const { form, setValue, setTouched, onSubmit } = useForm({ initialValues, schema, handleSubmit });

  return (
    <Layout title={requestData.title}>
      {createRequestCredentialsRequest.success ? (
        <Alert severity="success">
          <AlertTitle>Thank you</AlertTitle>
          Your request is currently under processing. When it will be accepted an email will be sent at the provided
          email address with a download url.
        </Alert>
      ) : (
        <Card component="form" onSubmit={onSubmit}>
          <CardContent>
            <FormRow>
              <TextField
                label="Name"
                value={form.values.name}
                onChange={(value) => setValue(value, 'name')}
                onBlur={() => setTouched('name')}
                error={form.errors.name && form.touched.name}
                helperText={form.touched.name ? form.errors.name?.message : ''}
                disableClearable
                required
              />
              <TextField
                label="Middle Name"
                value={form.values.middle_name}
                onChange={(value) => setValue(value, 'middle_name')}
                onBlur={() => setTouched('middle_name')}
                error={form.errors.middle_name && form.touched.middle_name}
                helperText={form.touched.middle_name ? form.errors.middle_name?.message : ''}
                disableClearable
              />
              <TextField
                label="Surname"
                value={form.values.surname}
                onChange={(value) => setValue(value, 'surname')}
                onBlur={() => setTouched('surname')}
                error={form.errors.surname && form.touched.surname}
                helperText={form.touched.surname ? form.errors.surname?.message : ''}
                disableClearable
                required
              />
            </FormRow>
            <FormRow>
              <TextField
                label="Institutional Affiliation"
                value={form.values.institutional_affiliation}
                onChange={(value) => setValue(value, 'institutional_affiliation')}
                onBlur={() => setTouched('institutional_affiliation')}
                error={form.errors.institutional_affiliation && form.touched.institutional_affiliation}
                helperText={
                  form.touched.institutional_affiliation ? form.errors.institutional_affiliation?.message : ''
                }
                disableClearable
                required
              />
              <TextField
                label="Email"
                value={form.values.email}
                onChange={(value) => setValue(value, 'email')}
                onBlur={() => setTouched('email')}
                error={form.errors.email && form.touched.email}
                helperText={form.touched.email ? form.errors.email?.message : ''}
                disableClearable
                required
              />
            </FormRow>
            <Box>
              <CheckBox
                label={
                  <>
                    Accept the{' '}
                    <Link to={privacyPolicy.path} target="_blank">
                      privacy conditions
                    </Link>
                  </>
                }
                value={form.values.privacy_policy}
                onChange={(value) => setValue(value, 'privacy_policy')}
                onBlur={() => setTouched('privacy_policy')}
                required
              />
            </Box>
            <Box>
              <CheckBox
                label={
                  <>
                    Accept the{' '}
                    <Link to={termsConditions.path} target="_blank">
                      data user policy
                    </Link>
                  </>
                }
                value={form.values.terms_conditions}
                onChange={(value) => setValue(value, 'terms_conditions')}
                onBlur={() => setTouched('terms_conditions')}
                required
              />
            </Box>
            <Paragraph>
              <CDICitation />
              <br />
              <CheckBox
                label="Accept the CDI citation"
                value={form.values.cdi_citation}
                onChange={(value) => setValue(value, 'cdi_citation')}
                onBlur={() => setTouched('cdi_citation')}
                required
              />
            </Paragraph>
            <SubmitWrapper>
              <ProgressButton
                color="secondary"
                variant="contained"
                type="submit"
                disabled={!form.isValid}
                inProgress={createRequestCredentialsRequest.inProgress}
              >
                Request credentials
              </ProgressButton>
            </SubmitWrapper>
            {createRequestCredentialsRequest.error && (
              <Alert severity="error">
                <AlertTitle>{createRequestCredentialsRequest.error.message}</AlertTitle>
                <pre>{createRequestCredentialsRequest.error.stack}</pre>
              </Alert>
            )}
          </CardContent>
        </Card>
      )}
    </Layout>
  );
}

export default RequestCredentials;
