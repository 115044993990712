import { Repository as TopSolutionRepository } from '@top-solution/utils';

export default class Repository extends TopSolutionRepository {
  constructor(resourceURL: string) {
    super({
      apiURL: `${process.env.REACT_APP_API_GATEWAY || ''}`,
      resourceURL,
    });
  }
}
