import React from 'react';

import { styled } from '@material-ui/core/styles';
import { Button, Container, ContainerProps, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { footerSectionList } from '../sections';
import { socialLinks } from '../socials';
import LoginButton from './LoginButton';

const FooterWrapper = styled('footer')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const Section = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const FooterLinks = styled('div')(({ theme }) => ({
  '& .MuiButtonBase-root': {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, 2, 0, 0),
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 1, 0),
    },
  },
}));

const AwsWrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),

  '& img': {
    maxHeight: theme.typography.fontSize * 3,
  },
}));

const year = new Date().getFullYear();

function Footer(props: ContainerProps) {
  return (
    <Container component={FooterWrapper} {...props}>
      <Section>
        <Typography variant="subtitle1" component="p">
          © {year} <strong>CDI Centro Diagnostico Italiano S.p.A.</strong>
        </Typography>
        <Typography variant="body2" component="p">
          Via Simone Saint Bon 20, 20147, Milano – P.I. 01721030151
          <br />
          Autorizzazioni: DGR 48295 del 21/02/2000 – Direttore Sanitario: Prof. Andrea Casasco
          <br />
          Struttura ambulatoriale accreditata Joint Commission International
        </Typography>
        <FooterLinks>
          {footerSectionList.map(({ icon, path, title, titleComponent }) =>
            path === '/login' ? (
              <LoginButton key={path} />
            ) : (
              <Button component={Link} to={path} key={path} startIcon={icon} color="inherit">
                {titleComponent || title}
              </Button>
            )
          )}
        </FooterLinks>
      </Section>
      <Section>
        <div>
          {socialLinks.map(({ title, icon, url }) => (
            <IconButton key={url} href={url} title={title} target="_blank" rel="noopener noreferrer" color="inherit">
              {icon}
            </IconButton>
          ))}
        </div>
        <AwsWrapper>
          <a href="https://aws.amazon.com/what-is-cloud-computing" title="Powered by AWS Cloud Computing">
            <img src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing" />
          </a>
        </AwsWrapper>
      </Section>
    </Container>
  );
}

export default Footer;
