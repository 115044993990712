import React, { useCallback, useEffect, useMemo } from 'react';
import { CircularProgress, Dialog, IconButton, styled } from '@material-ui/core';
import { Close as CloseIcon, ChevronRight as NextIcon, ChevronLeft as PrevIcon } from 'mdi-material-ui';

import { ResultRow } from '../../entities';
import { useImages } from '../../hooks/store/useImages';

const Button = styled(IconButton)({
  position: 'absolute',
  color: 'inherit',
});

const CloseButton = styled(Button)(({ theme }) => ({
  top: theme.spacing(-7),
  right: theme.spacing(-7),
}));

const PrevButton = styled(Button)(({ theme }) => ({
  top: '50%',
  left: theme.spacing(-7),
  marginTop: theme.spacing(-3),
}));

const NextButton = styled(Button)(({ theme }) => ({
  top: '50%',
  right: theme.spacing(-7),
  marginTop: theme.spacing(-3),
}));

const Lightbox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    overflow: 'visible',
    color: theme.palette.primary.contrastText,
    background: 'transparent',
    boxShadow: 'none',
  },
}));

type PreviewLightboxProps = {
  rows: ResultRow[];
  rowIndex: number | null;
  onChange: (index: number | null) => void;
};

function PreviewLightbox(props: PreviewLightboxProps) {
  const { rows, rowIndex, onChange } = props;
  const { thumbnails, getThumbnailUrl } = useImages();

  const currentRow = useMemo(() => (rowIndex !== null && rows[rowIndex]) || null, [rowIndex, rows]);

  const fetchUrl = useCallback(
    async (sha256: string) => {
      try {
        await getThumbnailUrl(sha256);
      } catch (error) {
        onChange(null);
      }
    },
    [getThumbnailUrl, onChange]
  );

  const handleKeyUp = useCallback(
    async (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          onChange(null);
          break;
        case 'ArrowLeft':
          if (rowIndex && rowIndex > 0) {
            onChange(rowIndex - 1);
          }
          break;
        case 'ArrowRight':
          if (rowIndex !== null && rowIndex < rows.length - 1) {
            onChange(rowIndex + 1);
          }
          break;
        default:
          break;
      }
    },
    [onChange, rowIndex, rows.length]
  );

  useEffect(() => {
    if (
      currentRow &&
      !thumbnails[currentRow.sha256]?.url &&
      !thumbnails[currentRow.sha256]?.inProgress &&
      !thumbnails[currentRow.sha256]?.error
    ) {
      fetchUrl(currentRow.sha256);
    }
  }, [currentRow, thumbnails, fetchUrl]);

  useEffect(() => {
    if (currentRow) {
      document.addEventListener('keyup', handleKeyUp);
    }
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp, currentRow]);

  if (rowIndex === null || !currentRow) {
    return null;
  }

  return (
    <>
      {!thumbnails[currentRow.sha256]?.error && (
        <Lightbox open={true} maxWidth="lg">
          {thumbnails[currentRow.sha256]?.inProgress && <CircularProgress color="inherit" />}
          {!thumbnails[currentRow.sha256]?.inProgress && thumbnails[currentRow.sha256]?.url && (
            <>
              <img src={thumbnails[currentRow.sha256].url as string} alt={currentRow.ImageFile} />
              <CloseButton onClick={() => onChange(null)}>
                <CloseIcon />
              </CloseButton>
              {rowIndex > 0 && (
                <PrevButton onClick={() => onChange(rowIndex - 1)}>
                  <PrevIcon />
                </PrevButton>
              )}
              {rowIndex < rows.length - 1 && (
                <NextButton onClick={() => onChange(rowIndex + 1)}>
                  <NextIcon />
                </NextButton>
              )}
            </>
          )}
        </Lightbox>
      )}
    </>
  );
}

export default PreviewLightbox;
