import React from 'react';
import { Card, CardContent, Typography, Link } from '@material-ui/core';

import { Layout, Paragraph } from '../components';
import { aboutUs } from '../sections';

function AboutUs() {
  return (
    <Layout title={aboutUs.title}>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h2">
            The AIforCOVID imaging archive
          </Typography>
          <Paragraph>
            Italy was the first western country hit by SARS-CoV-2 infection. Since then radiology imaging has played an
            increasingly key-role in the diagnosis of COVID-19 patients and in determining therapeutic options, patient
            care management and new research directions.
          </Paragraph>
          <Paragraph>
            For this reason, CDI Centro Diagnostico Italiano, an important health institute placed in Milan and
            accredited by Joint Commission International, promoted AIforCOVID project: a multi-centre, observational,
            retrospective, no-profit study aiming to develop AI-based algorithm for the prediction of COVID-19 patients
            clinical outcomes.
          </Paragraph>
          <Paragraph>
            Import public and private clinical and research institutes participated in the project. COVID-19 data come
            from CDI – Centro Diagnostico Italiano (Milan), Bracco Imaging S.p.A. (Milan), Fondazione IRCCS Ca’ Granda Ospedale Maggiore Policlinico
            (Milan), Fondazione IRCCS Policlinico San Matteo (Pavia), Azienda ospedaliero-universitaria Careggi
            (Florence), ASST Santi Paolo e Carlo (Milan), ASST Fatebenefratelli-Sacco (Milan), ASST Ospedale San Gerardo
            (Monza), Ospedale Casa Sollievo della Sofferenza (San Giovanni Rotondo), and Istituto Italiano di Tecnologia
            (Genova).
          </Paragraph>
          <Paragraph>
            Data analysis and the development of artificial intelligence algorithms were carried out by the Italian
            Insitute of Technology (IIT) of Genova, and Campus Bio-medico of Roma. The project was also powered by
            Amazon Web Services, and Bracco Imaging.
          </Paragraph>
          <Paragraph>
            All the images archived in this dataset are chest x-ray examination performed at the hospital admission in
            COVID-19 suspected patients. All 823 patient included in this database were confirmed positive for SARS-CoV-2
            at the molecular test.
          </Paragraph>
          <Paragraph>
            Examinations were performed in Italy during the first pandemic wave, and patients were divided into 2 groups
            basing to their clinical condition: mild (no ventilatory support) and severe (non-invasive ventilation
            support or ICU, or dead).
          </Paragraph>
          <Paragraph>
            Images and clinical data were analyzed using 3 machine learning methods and radiomics: support vector
            machine (SVM), logistic regression (LR) and decision trees (DT). Model were validated with 10-fold and
            center-wise cross-validation. Our AI-based algorithms showed 74%, 75%, 65% and 73%, 75%, 60% accuracy with
            10-fold and center-wise cross validation respectively.
          </Paragraph>
          <Paragraph>We hope this collection will help you.</Paragraph>
          <Paragraph>
            If you too want to share your COVID-19 patient data, you will contribute to the creation of a larger
            multicentre database that will facilitate the search for innovative solutions based on artificial
            intelligence.
          </Paragraph>
          {/* prettier-ignore */}
          <Paragraph>
            <strong>Please cite this reference if you use our data:</strong> “Soda P, et al. AIforCOVID: Predicting the clinical outcomes in patients with COVID-19 applying AI to chest-X-rays. An Italian multicentre
            study. Med Image Anal. 2021 Aug 28;74:102216. doi: 10.1016/j.media.2021.102216 (<Link href="https://www.sciencedirect.com/science/article/pii/S1361841521002619">download here</Link>)”
          </Paragraph>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default AboutUs;
