import React, { useMemo, useState } from 'react';
import { formatISO } from 'date-fns';
import { Alert, Button, Dialog, DialogActions, DialogContent, styled, Typography } from '@material-ui/core';
import { DropzoneArea, AlertType } from 'material-ui-dropzone';
import { ProgressButton, TextField } from '@top-solution/mui-inputs';

import { CircularPercentProgress, Spacer } from '../../components';
import { useUserFile } from '../../hooks/store/useUserFile';

const UploadWrapper = styled(Dialog)(({ theme }) => ({
  marginBottom: theme.spacing(2),

  '& .MuiDropzoneArea-root': {
    margin: theme.spacing(0, 0, 2),
  },

  '& .MuiDropzonePreviewList-root': {
    margin: theme.spacing(2, 0),
  },

  '& .uploadProgressRow': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },

  '& .fileName': {
    fontFamily: 'monospace',
    marginLeft: theme.spacing(1),
  },
}));

const ExcelFileRegexp = /\.xl(s|sx|w|tx|sm|sb|a|am)$/i;

type UploadDialogProps = {
  open: boolean;
  onClose: () => void;
};

function UploadDialog(props: UploadDialogProps) {
  const { open, onClose } = props;
  const [filesToUpload, setFilesToUpload] = useState([] as File[]);
  const [directoryName, setDirectoryName] = useState(formatISO(new Date()));
  const [dropzoneError, setDropzoneError] = useState('');
  const { uploadUserFileList, uploadUserFileListRequest, uploadUserFileListClear } = useUserFile();

  const excelFile = useMemo(() => filesToUpload.find(({ name }) => ExcelFileRegexp.test(name)), [filesToUpload]);

  const handleDropzoneAlert = (message: string, variant: AlertType) => {
    if (variant === 'error') {
      setDropzoneError(message);
    }
  };

  const upload = async () => {
    await uploadUserFileList(directoryName, filesToUpload);
    handleClose();
  };

  const handleClose = () => {
    uploadUserFileListClear();
    onClose();
  };

  return (
    <UploadWrapper open={open} maxWidth="sm" fullWidth>
      <DialogContent>
        {!uploadUserFileListRequest.inProgress && !uploadUserFileListRequest.completed ? (
          <>
            <TextField
              label="Directory name"
              value={directoryName}
              onChange={setDirectoryName}
              error={!directoryName}
              helperText={(!directoryName && 'Required field') || ' '}
              fullWidth
              required
            />
            <DropzoneArea
              showAlerts={false}
              showPreviews={true}
              showPreviewsInDropzone={false}
              showFileNamesInPreview={true}
              useChipsForPreview={true}
              maxFileSize={Number.MAX_SAFE_INTEGER}
              filesLimit={Number.MAX_SAFE_INTEGER}
              previewText="Files to be uploaded:"
              onChange={setFilesToUpload}
              onAlert={handleDropzoneAlert}
            />
            <Typography variant="body2">Add images and at least 1 Excel file</Typography>
            {dropzoneError && <Alert severity="error">{dropzoneError}</Alert>}
          </>
        ) : (
          <div>
            {filesToUpload.map(({ name }) => (
              <div className="uploadProgressRow" key={name}>
                <CircularPercentProgress value={uploadUserFileListRequest.progress[name]} />
                <span className="fileName">{name}</span>
              </div>
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!uploadUserFileListRequest.inProgress && !uploadUserFileListRequest.completed ? (
          <>
            <Button onClick={() => onClose()}>Cancel</Button>
            <Spacer />
            <Button
              variant="contained"
              color="primary"
              disabled={filesToUpload.length < 2 || !excelFile || !directoryName}
              onClick={upload}
            >
              Upload
            </Button>
          </>
        ) : (
          <>
            <Spacer />
            <ProgressButton
              variant="contained"
              color="primary"
              inProgress={uploadUserFileListRequest.inProgress}
              onClick={handleClose}
            >
              Close
            </ProgressButton>
          </>
        )}
      </DialogActions>
    </UploadWrapper>
  );
}

export default UploadDialog;
