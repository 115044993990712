import React from 'react';
import { Divider, Drawer, DrawerProps, List, styled } from '@material-ui/core';

import { menuSectionList } from '../../sections';
import { SectionLink } from './SectionLink';

const MenuList = styled(List)({
  minWidth: '36ch',
});

const menuItems = menuSectionList.map((section, index) =>
  !section ? <Divider key={index} /> : <SectionLink section={section} key={index} />
);

type MenuDrawerProps = Pick<DrawerProps, 'open' | 'onClose'>;

export function MenuDrawer(props: MenuDrawerProps) {
  const { open, onClose } = props;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <MenuList>{menuItems}</MenuList>
    </Drawer>
  );
}
