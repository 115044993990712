import React, { forwardRef, useMemo } from 'react';
import { NavLink, LinkProps } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { AppSection, login, logout } from '../../sections';
import { useCurrentUserResource } from '../../hooks/store/useAuth';

type SectionLinkProps = {
  section: AppSection;
};

export function SectionLink(props: SectionLinkProps) {
  const [currentUser] = useCurrentUserResource();
  let { section } = props;

  if (section === login && currentUser) {
    section = logout;
  }

  const { path } = section;

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function ListItemComponent(itemProps, ref) {
        return <NavLink to={path} activeClassName="Mui-selected" exact ref={ref} {...itemProps} />;
      }),
    [path]
  );

  return (
    <ListItem button component={renderLink}>
      {section.icon && <ListItemIcon>{section.icon}</ListItemIcon>}
      <ListItemText>{section.title}</ListItemText>
    </ListItem>
  );
}
