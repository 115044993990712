import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import 'typeface-montserrat';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0a4682',
    },
    secondary: {
      main: '#329967',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 14,
  },
});

export default responsiveFontSizes(theme);
