import Repository from './Repository';

export type RequestCredentialsPayload = {
  name: string;
  middle_name: string;
  surname: string;
  email: string;
  institutional_affiliation: string;
};

class RequestCredentialsRepository extends Repository {
  async create(payload: RequestCredentialsPayload) {
    return await this.axios.post('/', payload);
  }
}

export default new RequestCredentialsRepository('/export_request');
