import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { HiddenColumns } from '../../entities';
import { RootState } from '../../store/reducers';

import {
  SET_PAGE_SIZE,
  SET_HIDDEN_COLUMNS,
  SetPageSizeAction,
  SetHiddenColumnsAction,
} from '../../store/reducers/settings';
import { useTracking } from '../useTracking';

export function useSettings() {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const { pageSize, hiddenColumns } = useSelector((state: RootState) => state.settings);

  const setPageSize = useCallback(
    (pageSize: number) => {
      dispatch<SetPageSizeAction>({ type: SET_PAGE_SIZE, pageSize });
      trackEvent('set', 'config', 'pageSize', pageSize);
    },
    [dispatch, trackEvent]
  );

  const setHiddenColumns = useCallback(
    (hiddenColumns: HiddenColumns) => {
      dispatch<SetHiddenColumnsAction>({ type: SET_HIDDEN_COLUMNS, hiddenColumns });
      trackEvent('set', 'config', 'hiddenColumns', Object.keys(hiddenColumns).length);
    },
    [dispatch, trackEvent]
  );

  return {
    pageSize,
    hiddenColumns,
    setPageSize,
    setHiddenColumns,
  };
}
