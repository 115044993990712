import { AlmostBoolean, COVIDPositive, DaysFever, Position, ResultColumn, Sex, TherapyEparine } from '../../entities';

const columns: ResultColumn[] = [
  { field: 'ImageFile', type: 'String', headerName: 'Image File' },
  { field: 'Hospital', type: 'String', headerName: 'Hospital' },
  { field: 'Age', type: 'Number', headerName: 'Age' },
  { field: 'Sex', type: Sex, headerName: 'Sex' },
  { field: 'Positivity at admission', type: COVIDPositive, headerName: 'Positivity at admission' },
  { field: 'Temp_C', type: 'Number', precision: 1, headerName: 'Temp °C', rowCellProps: { align: 'right' } },
  { field: 'DaysFever', type: DaysFever, headerName: 'Days Fever', rowCellProps: { align: 'right' } },
  { field: 'Cough', type: AlmostBoolean, headerName: 'Cough' },
  { field: 'DifficultyInBreathing', type: AlmostBoolean, headerName: 'Difficulty in Breathing' },
  { field: 'Therapy_anti-inflammatory ', type: AlmostBoolean, headerName: 'Therapy Anti-Inflammatory' },
  { field: 'Therapy_Tocilizumab', type: AlmostBoolean, headerName: 'Therapy Tocilizumab' },
  { field: 'Therapy_Anakinra', type: AlmostBoolean, headerName: 'Therapy Anakinra' },
  { field: 'Therapy_hydroxychloroquine', type: AlmostBoolean, headerName: 'Therapy Hydroxychloroquine' },
  { field: 'Therapy_antiviral', type: AlmostBoolean, headerName: 'Therapy Antiviral' },
  { field: 'Therapy_Eparine', type: TherapyEparine, headerName: 'Therapy Eparine' },
  { field: 'WBC', type: 'Number', precision: 2, headerName: 'WBC (10^9/L)', rowCellProps: { align: 'right' } },
  { field: 'RBC', type: 'Number', precision: 2, headerName: 'RBC (10^12/L)', rowCellProps: { align: 'right' } },
  { field: 'CRP', type: 'Number', precision: 2, headerName: 'CRP (mg/dL)', rowCellProps: { align: 'right' } },
  { field: 'Fibrinogen', type: 'Number', headerName: 'Fibrinogen', rowCellProps: { align: 'right' } },
  { field: 'Glucose', type: 'Number', headerName: 'Glucose', rowCellProps: { align: 'right' } },
  { field: 'PCT', type: 'Number', precision: 2, headerName: 'PCT (mg/dL)', rowCellProps: { align: 'right' } },
  { field: 'LDH', type: 'Number', headerName: 'LDH (U/L)', rowCellProps: { align: 'right' } },
  { field: 'INR', type: 'Number', precision: 2, headerName: 'INR', rowCellProps: { align: 'right' } },
  { field: 'D-dimer', type: 'Number', headerName: 'D-dimer', rowCellProps: { align: 'right' } },
  { field: 'Ox_percentage', type: 'Number', headerName: 'Ox %', rowCellProps: { align: 'right' } },
  { field: 'PaO2', type: 'Number', headerName: 'PaO2', rowCellProps: { align: 'right' } },
  { field: 'SaO2', type: 'Number', headerName: 'SaO2', rowCellProps: { align: 'right' } },
  { field: 'PaCO2', type: 'Number', headerName: 'PaCO2', rowCellProps: { align: 'right' } },
  { field: 'pH', type: 'Number', precision: 2, headerName: 'pH', rowCellProps: { align: 'right' } },
  { field: 'CardiovascularDisease', type: AlmostBoolean, headerName: 'Cardiovascular Disease' },
  { field: 'IschemicHeartDisease', type: AlmostBoolean, headerName: 'Ischemic Heart Disease' },
  { field: 'AtrialFibrillation', type: AlmostBoolean, headerName: 'Atrial Fibrillation' },
  { field: 'HeartFailure', type: AlmostBoolean, headerName: 'Heart Failure' },
  { field: 'Ictus', type: AlmostBoolean, headerName: 'Ictus' },
  { field: 'HighBloodPressure', type: AlmostBoolean, headerName: 'High Blood Pressure' },
  { field: 'Diabetes', type: AlmostBoolean, headerName: 'Diabetes' },
  { field: 'Dementia', type: AlmostBoolean, headerName: 'Dementia' },
  { field: 'BPCO', type: AlmostBoolean, headerName: 'BPCO' },
  { field: 'Cancer', type: AlmostBoolean, headerName: 'Cancer' },
  { field: 'Chronic Kidney disease', type: AlmostBoolean, headerName: 'Chronic Kidney disease' },
  { field: 'RespiratoryFailure', type: AlmostBoolean, headerName: 'Respiratory Failure' },
  { field: 'Obesity', type: AlmostBoolean, headerName: 'Obesity' },
  { field: 'Position', type: Position, headerName: 'Position' },
  { field: 'Prognosis', type: 'String', headerName: 'Prognosis' },
  { field: 'Death', type: AlmostBoolean, headerName: 'Death' },
  { field: 'Row_number', type: 'Number', headerName: 'Row Number' },
  { field: 'sha256', type: 'String', headerName: 'SHA-256' },
  { field: 'file_path', type: 'String', headerName: 'File Path' },
];

export default columns;
