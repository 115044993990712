import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  styled,
} from '@material-ui/core';
import { CloudDownload as DownloadIcon, ImageSearch as OpenLightboxIcon } from 'mdi-material-ui';

import { ResultRow } from '../../entities';
import { useImages } from '../../hooks/store/useImages';
import { NoWrap } from '../../components';

const ActionButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1 / 2),
  position: 'relative',

  '& .MuiCircularProgress-root': {
    position: 'absolute',
    top: theme.spacing(1 / 2),
    left: theme.spacing(1 / 2),
    bottom: theme.spacing(1 / 2),
    right: theme.spacing(1 / 2),
  },
}));

type RowActionsProps = {
  row: ResultRow;
  onOpenImagePreviewClick: () => void;
};

function RowActions(props: RowActionsProps) {
  const { row, onOpenImagePreviewClick } = props;
  const { clearDownloadStatus, downloadImage, downloads } = useImages();

  return (
    <NoWrap>
      <ActionButton
        size="small"
        onClick={() => downloadImage(row.sha256, row.ImageFile)}
        disabled={downloads[row.sha256]?.inProgress}
      >
        {downloads[row.sha256]?.inProgress && (
          <CircularProgress value={downloads[row.sha256].progress} size="small" color="secondary" />
        )}
        <DownloadIcon />
      </ActionButton>
      <ActionButton size="small" onClick={onOpenImagePreviewClick}>
        <OpenLightboxIcon />
      </ActionButton>
      {downloads[row.sha256]?.error && (
        <Dialog open={true}>
          <DialogTitle>Download error</DialogTitle>
          <DialogContent>An error occured during download of image {row.ImageFile}</DialogContent>
          <DialogActions>
            <Button onClick={() => clearDownloadStatus(row.sha256)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </NoWrap>
  );
}

export default RowActions;
