import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Button, Paper, Skeleton, styled, TablePagination } from '@material-ui/core';

import { ColumnSort, SearchFilter } from '../../entities';
import { useImages } from '../../hooks/store/useImages';
import { accessData } from '../../sections';
import { Layout, NoWrap } from '../../components';
import DataTable from './DataTable';
import SearchSettings from './SearchSettings';
import { useSettings } from '../../hooks/store/useSettings';

const AccessDataLayout = styled(Layout)(({ theme }) => ({
  '& .ContentWrapper-root': {
    padding: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)', // Header height
    },

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 48px)', // Header height
    },

    '& .MuiContainer-root': {
      height: '100%',
    },
  },
}));

const PageWrapper = styled(Paper)({
  overflow: 'auto',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  '& .MuiTableContainer-root': {
    flex: '1 0 0%',
  },

  '& .MuiTableRow-head': {
    verticalAlign: 'bottom',
  },
});

const Error = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(2, 2, 0),
}));

const LoaderWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  flex: '1 0 0%',
  overflow: 'hidden',
}));

const FilterWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',

    '& > .MuiButtonBase-root': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const rowsPerPageOptions = [10, 25, 50, 100];

function AccessData() {
  const { pageSize, setPageSize, hiddenColumns, setHiddenColumns } = useSettings();
  const { searchImages, searchImagesRequest, searchImagesResults } = useImages();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([] as SearchFilter[]);
  const [sort, setSort] = useState(null as null | ColumnSort);

  const handlePageChange = (event: unknown, page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangeFilters = (filters: SearchFilter[]) => {
    setFilters(filters);
    setPage(0);
  };

  const openInNewTab = (url: string | undefined) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    if (
      page !== searchImagesRequest.page ||
      pageSize !== searchImagesRequest.pageSize ||
      filters !== searchImagesRequest.filters ||
      sort !== searchImagesRequest.sort
    ) {
      searchImages(page, pageSize, filters, sort);
    }
  }, [pageSize, page, filters, sort, searchImages, searchImagesRequest]);

  return (
    <AccessDataLayout maxWidth={false} disableGutters>
      <Helmet>
        <title>{accessData.title} | AI for COVID</title>
      </Helmet>
      <PageWrapper>
        <FilterWrapper>
          <SearchSettings
            filters={filters}
            setFilters={handleChangeFilters}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
          />
          {/*prettier-ignore*/}
          <Button variant="contained" color="primary" onClick={() => openInNewTab('https://jqwa5b5n7d.execute-api.eu-south-1.amazonaws.com/prod/download/file_export')}>
            <NoWrap>Download all the data</NoWrap>
          </Button>
        </FilterWrapper>
        {searchImagesRequest.error ? (
          <Error severity="error">{searchImagesRequest.error.message}</Error>
        ) : searchImagesRequest.inProgress ? (
          <LoaderWrapper>
            {[...Array(pageSize)].map((_, index) => (
              <Skeleton key={index} height={34} />
            ))}
          </LoaderWrapper>
        ) : searchImagesResults?.data.length === 0 ? (
          <Error severity="warning">Can&apos;t find any result matching query</Error>
        ) : (
          <DataTable sort={sort} setSort={setSort} />
        )}
        {searchImagesResults?.paging.total && (
          <TablePagination
            component="div"
            count={searchImagesResults.paging.total}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </PageWrapper>
    </AccessDataLayout>
  );
}

export default AccessData;
