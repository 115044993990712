import { API } from '@aws-amplify/api';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnSort, ESResponse, SearchFilter, SearchResult } from '../../entities';
import { RootState } from '../../store/reducers';
import {
  DownloadImageClearAction,
  DownloadImageFailureAction,
  DownloadImageProgressAction,
  DownloadImageRequestAction,
  DownloadImageSuccessAction,
  DOWNLOAD_IMAGE_CLEAR,
  DOWNLOAD_IMAGE_FAILURE,
  DOWNLOAD_IMAGE_PROGRESS,
  DOWNLOAD_IMAGE_REQUEST,
  DOWNLOAD_IMAGE_SUCCESS,
  ReadThumbnailUrlFailureAction,
  ReadThumbnailUrlRequestAction,
  ReadThumbnailUrlSuccessAction,
  READ_THUMBNAIL_URL_FAILURE,
  READ_THUMBNAIL_URL_REQUEST,
  READ_THUMBNAIL_URL_SUCCESS,
  SearchImagesFailureAction,
  SearchImagesRequestAction,
  SearchImagesSuccessAction,
  SEARCH_IMAGES_FAILURE,
  SEARCH_IMAGES_REQUEST,
  SEARCH_IMAGES_SUCCESS,
} from '../../store/reducers/images';
import { useTracking } from '../useTracking';

export function useImages() {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const searchImagesResults = useSelector((state: RootState) => state.images.results);
  const searchImagesRequest = useSelector((state: RootState) => state.images.requests.search);
  const downloads = useSelector((state: RootState) => state.images.requests.downloads);
  const thumbnails = useSelector((state: RootState) => state.images.requests.thumbnails);

  const searchImages = useCallback(
    (page: number, pageSize: number, filters: SearchFilter[], sort: ColumnSort | null) => {
      dispatch<SearchImagesRequestAction>({ type: SEARCH_IMAGES_REQUEST, page, pageSize, filters, sort });
      const query =
        filters.length > 0
          ? {
              bool: {
                must: filters.map((filter) => ({
                  [filter.operator.type]: {
                    [filter.column.field]: { [filter.operator.verb]: filter.value },
                  },
                })),
              },
            }
          : undefined;
      const search = async () => {
        try {
          const response: ESResponse = await API.post('APISearchGateway', `/esproxy/metadata/_search`, {
            body: {
              from: page * pageSize,
              size: pageSize,
              query,
              sort: sort
                ? [
                    {
                      [sort.field]: sort.ascending ? 'asc' : 'desc',
                    },
                  ]
                : undefined,
            },
          });
          const results: SearchResult = {
            data: response.hits.hits.map((hit) => hit._source),
            paging: {
              total: response.hits.total.value,
            },
          };
          dispatch<SearchImagesSuccessAction>({ type: SEARCH_IMAGES_SUCCESS, results });
          trackEvent('query', 'images', sort ? `sort: sort.field` : undefined, filters.length);
          return results;
        } catch (error) {
          dispatch<SearchImagesFailureAction>({ type: SEARCH_IMAGES_FAILURE, error });
        }
      };
      return search();
    },
    [dispatch, trackEvent]
  );

  const getImageUrl = useCallback(
    (sha256: string) => {
      dispatch<DownloadImageRequestAction>({ type: DOWNLOAD_IMAGE_REQUEST, sha256 });
      const getImageUrl = async () => {
        try {
          const { url } = await API.get('APISearchGateway', `/download/${sha256}`, { body: { query: '' } });

          dispatch<DownloadImageSuccessAction>({ type: DOWNLOAD_IMAGE_SUCCESS, sha256, url });
          return url as string;
        } catch (error) {
          dispatch<DownloadImageFailureAction>({ type: DOWNLOAD_IMAGE_FAILURE, sha256, error });
          throw error;
        }
      };
      return getImageUrl();
    },
    [dispatch]
  );

  const downloadImage = useCallback(
    (sha256: string, filename: string) => {
      const download = async () => {
        const url = await getImageUrl(sha256);

        const { data } = await axios.get(url, {
          responseType: 'blob',
          onDownloadProgress: (event) => {
            const progress = Math.round((event.loaded * 100) / event.total);
            dispatch<DownloadImageProgressAction>({ type: DOWNLOAD_IMAGE_PROGRESS, sha256, progress });
          },
        });
        saveAs(data, filename);
        trackEvent('download', 'images', sha256);
      };
      return download();
    },
    [dispatch, getImageUrl, trackEvent]
  );

  const clearDownloadStatus = useCallback(
    (sha256: string) => {
      dispatch<DownloadImageClearAction>({ type: DOWNLOAD_IMAGE_CLEAR, sha256 });
    },
    [dispatch]
  );

  const getThumbnailUrl = useCallback(
    (sha256: string) => {
      dispatch<ReadThumbnailUrlRequestAction>({ type: READ_THUMBNAIL_URL_REQUEST, sha256 });
      const getThumbnailUrl = async () => {
        try {
          const { url } = await API.get('APISearchGateway', `/download/thumbnail/${sha256}`, { body: { query: '' } });

          dispatch<ReadThumbnailUrlSuccessAction>({ type: READ_THUMBNAIL_URL_SUCCESS, sha256, url });
          return url as string;
        } catch (error) {
          dispatch<ReadThumbnailUrlFailureAction>({ type: READ_THUMBNAIL_URL_FAILURE, sha256, error });
          throw error;
        }
      };
      return getThumbnailUrl();
    },
    [dispatch]
  );

  return {
    searchImagesRequest,
    searchImagesResults,
    searchImages,
    downloads,
    downloadImage,
    clearDownloadStatus,
    getImageUrl,
    thumbnails,
    getThumbnailUrl,
  };
}
