import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ChevronDown as ExpandIcon } from 'mdi-material-ui';

import { Layout, Paragraph } from '../components';
import { privacyPolicy } from '../sections';

function PrivacyPolicy() {
  return (
    <Layout title={privacyPolicy.title}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">English</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h4" component="h2">
            Privacy policy art. 12, 13 and 14, EU Regulation 2016/679
          </Typography>
          <Paragraph>
            In accordance with the Regulation on the protection of individuals with regard to the processing of personal
            data and on the free movement of such data, namely Regulation (EU) No. 2016/679 of the European Parliament
            and of the Council of 27 April 2016 (hereafter, also, the “Regulation”), CDI – Centro Diagnostico Italiano
            S.p.A., based in Milano, Simone Saint Bon 20, (hereafter, also ”Company”), as Data Controller, he is
            required to provide some information regarding the personal data collected from the “Data Subjects”.
          </Paragraph>
          <Paragraph>
            The data held by the Data Controller are normally collected directly from the Data Subjects and occasionally
            also from third parties.
            <br />
            In the event that the personal data of the interested party are not collected directly from the same, the
            Data Controller, pursuant to Article 14, letter g) of the Regulation, informs that no automated
            decision-making processes are put in place, including the profiling of which to art. 22, paragraphs 1 and 4.
          </Paragraph>
          <Paragraph>The personal data are processed for the following purposes:</Paragraph>
          <Typography component="ol">
            <li>
              purposes strictly connected and instrumental to the release of database access credentials “The AIforCOVID
              imaging archive”;
            </li>
            <li>
              purposes connected to obligations established by national or supranational laws or regulations, as well as
              by provisions of national or supranational bodies or authorities.
            </li>
          </Typography>
          <Paragraph>
            In relation to the indicated purposes, the processing of personal data will take place in such a way as to
            guarantee their security and confidentiality, as well as respect for the rights and freedoms of the
            interested parties and may be carried out through manual, IT and telematic tools designed to store, manage
            and transmit the data themselves. The logic of the processing will be strictly related to the illustrated
            purposes.
            <br />
            The provision of data for the aforementioned purposes is optional, however a refusal to do so would in fact
            make it impossible to assign and manage access.
            <br />
            The processing of personal data for the purposes indicated above does not require the consent of the
            interested parties.
            <br />
            The processing of personal data for the purposes indicated above is lawful pursuant to and for the purposes
            of art. 6, letters b) and f) of EU Regulation 679/2016.
          </Paragraph>
          <Paragraph>
            The data of the interested parties may be disclosed to the following categories of third parties:
          </Paragraph>
          <Typography component="ul">
            <li>
              public authorities in the context of the fulfilment of specific legal obligations or on the basis of
              legitimate provisions;
            </li>
            <li>
              administrative, judicial and tax authorities, in the cases and with the limitations provided for by law;
            </li>
            <li>consultants and / or insurers of the Owner;</li>
            <li>service providers and / or consultants</li>
          </Typography>
          <Paragraph>
            The personal data collected and processed by us as indicated above may be disclosed to subjects with
            registered office or residence in the European Union, also subject to the Regulations.
            <br />
            The communication of data to the categories of subjects indicated above does not require the consent of the
            interested parties.
          </Paragraph>
          <Paragraph>
            The data of the interested parties will be disclosed to the personnel authorized by the owner of the
            execution of the assignment pursuant to art. 29 of the Regulation.
          </Paragraph>
          <Paragraph>
            The communication of data to the categories of subjects indicated above does not require the consent of the
            interested parties.
          </Paragraph>
          <Paragraph>
            The data of the interested parties will be disclosed to the personnel authorized by the owner of the
            execution of the assignment pursuant to art. 29 of the Regulation.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Time duration of processing and storage of personal data
          </Typography>
          <Paragraph>
            The treatments referred to in this information will have the duration strictly necessary for the obligations
            imposed on the Data Controller by national and / or supranational laws, as well as by the laws of the
            countries to which the data will eventually be transferred. By way of example only, personal data will be
            processed and stored for the entire duration of the contractual relationship and, subsequently, for 10
            years, the ordinary limitation period.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Data Controller and Personal Data Protection Officer
          </Typography>
          <Paragraph>
            Joint data controllers are CDI - Centro Diagnostico Italiano and Bionics S.r.l., with headquarters in Milan,
            Simone Saint Bon 20, E-mail privacy@cdi.it;
            <br />
            The Personal Data Protection Officer can be reached at the e-mail address privacy@cdi.it.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Rights of interested parties (articles from 15 to 22 of the Regulation)
          </Typography>
          <Paragraph>
            Finally, we inform you that articles from 15 to 22 of the Regulation grant the interested parties the
            exercise of specific rights. In particular, interested parties will be able to obtain from the Data
            Controller, with regard to their personal data: access (Article 15); rectification (Article 16);
            cancellation - oblivion - (Article 17); limitation to processing (Article 18); notification in case of
            rectification, cancellation or limitation (Article 19); portability (Article 20); right to object (Article
            21) and not subjected to automated decision-making and profiling processes (Article 22). The interested
            party has the right to lodge a complaint with the Supervisory Authority.
          </Paragraph>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">Italiano</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h4" component="h2">
            Informativa ai sensi degli artt. 12, 13 e 14 del Regolamento UE 2016/679
          </Typography>
          <Paragraph>
            Ai sensi e per gli effetti del Regolamento in oggetto, dettato in materia di “protezione delle persone
            fisiche con riguardo al trattamento dei dati personali”, CDI – Centro Diagnostico Italiano S.p.A., con sede
            in Milano, via Simone Saint Bon 20, (di seguito, ”Società”), in qualità di Titolare del trattamento, è
            tenuto a fornire alcune informazioni riguardanti i dati personali raccolti presso gli “Interessati”.
          </Paragraph>
          <Paragraph>
            I dati in possesso del Titolare sono normalmente raccolti direttamente presso gli Interessati e
            occasionalmente anche presso terzi. Nel caso in cui i dati personali dell’interessato non siano raccolti
            direttamente presso lo stesso, il Titolare, ai sensi dell’art 14, lettera g) del Regolamento, informa che
            non sono posti in essere processi decisionali automatizzati, compresa la profilazione di cui all’art. 22,
            paragrafi 1 e 4.
          </Paragraph>
          <Paragraph>
            I dati sono trattati nell’ambito della normale attività del Titolare, con le seguenti finalità:
          </Paragraph>
          <Typography component="ol">
            <li>
              finalità strettamente connesse e strumentali al rilascio delle credenziali di accesso al database “The
              AIforCOVID imaging archive”;
            </li>

            <li>
              finalità connesse ad obblighi previsti da disposizioni di leggi o regolamenti nazionali o sovranazionali,
              nonché da provvedimenti di enti o autorità, nazionali o sovranazionali.
            </li>
          </Typography>
          <Paragraph>
            In relazione alle indicate finalità, il trattamento dei dati personali, avverrà in modo da garantirne la
            sicurezza e la riservatezza, nonché il rispetto dei diritti e delle libertà degli interessati e potrà essere
            effettuato attraverso strumenti manuali, informatici e telematici atti a memorizzare, gestire e trasmettere
            i dati stessi. Le logiche del trattamento saranno strettamente correlate alle illustrate finalità.
            <br />
            Il conferimento dei dati per le sopra illustrate finalità è facoltativo, tuttavia un rifiuto in merito
            comporterebbe di fatto l’impossibilità di attribuire e gestire gli accessi.
            <br />
            Il trattamento dei dati personali per le finalità sopra indicate non richiede il consenso degli interessati.
            <br />
            Il trattamento dei dati personali per le finalità sopra indicate è lecito ai sensi e per gli effetti
            dell’art. 6, lettere b) ed f) del Regolamento UE 679/2016.
          </Paragraph>
          <Paragraph>
            I dati degli Interessati potranno essere comunicati alle seguenti categorie di soggetti terzi:
          </Paragraph>
          <Typography component="ul">
            <li>
              pubbliche autorità nell’ambito dell’adempimento di specifici obblighi di legge o in base a provvedimenti
              legittimi;
            </li>
            <li>autorità amministrative, giudiziarie e fiscali, nei casi e con le limitazioni previsti dalla legge;</li>
            <li>consulenti e/o assicuratori del Titolare;</li>
            <li>providers di servizi e/o consulenti</li>
          </Typography>
          <Paragraph>
            I dati personali da noi raccolti e trattati come sopra indicato potranno essere comunicati a soggetti con
            sede o residenza nell’Unione Europea, soggetti anch’essi al Regolamento.
            <br />
            La comunicazione dei dati alle categorie di soggetti sopra indicati non necessita del consenso degli
            Interessati.
          </Paragraph>
          <Paragraph>
            Dei dati degli Interessati verrà a conoscenza il personale autorizzato dal Titolare dell’esecuzione
            dell’incarico ex art. 29 del Regolamento.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Durata temporale dei trattamenti e della conservazione dei dati personali
          </Typography>
          <Paragraph>
            I trattamenti di cui alla presente informativa avranno la durata strettamente necessaria agli adempimenti
            imposti al Titolare dalle leggi nazionali e/o sovranazionali, nonché dalle leggi dei Paesi in cui i dati
            saranno eventualmente trasferiti. A titolo meramente esemplificativo, i dati personali saranno trattati e
            conservati per tutta la durata del rapporto contrattuale e, successivamente, per 10 anni, termine di
            prescrizione ordinaria.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Titolare del Trattamento e Responsabile della Protezione dei dati personali
          </Typography>
          <Paragraph>
            Contitolari del trattamento sono CDI – Centro Diagnostico Italiano e Bionics S.r.l., con sede in Milano, via
            Simone Saint Bon 20, E-mail privacy@cdi.it;
            <br />
            Il Responsabile della Protezione dei dati personali è raggiungibile all’indirizzo di posta elettronica
            privacy@cdi.it.
          </Paragraph>
          <Typography variant="h5" component="h3">
            Diritti degli interessati (artt. da 15 a 22 del Regolamento)
          </Typography>
          <Paragraph>
            Informiamo, infine, che gli artt. Da 15 a 22 del Regolamento conferiscono agli interessati l’esercizio di
            specifici diritti. In particolare, gli interessati potranno ottenere dal Titolare, in ordine ai propri dati
            personali: l’accesso (art. 15); la rettifica (art. 16); la cancellazione – oblio - (art. 17); la limitazione
            al trattamento (art. 18); la notifica in caso di rettifica, cancellazione o limitazione (art. 19); la
            portabilità (art. 20); diritto di opposizione (art. 21) e la non sottoposizione a processi decisionali
            automatizzati e profilazione (art. 22). L’interessato ha diritto di proporre reclamo all’Autorità di
            Controllo.
          </Paragraph>
        </AccordionDetails>
      </Accordion>
    </Layout>
  );
}

export default PrivacyPolicy;
