import React, { useCallback } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import * as yup from 'yup';
import { Alert, Card, CardContent, Container, styled } from '@material-ui/core';
import { useForm } from '@top-solution/use-form';
import { TextField, ProgressButton } from '@top-solution/mui-inputs';

import { FormRow } from '../components';
import { login as loginSection, accessData, home } from '../sections';
import logo from '../logo/full.svg';
import { useAuth, useCurrentUserResource } from '../hooks/store/useAuth';

const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Logo = styled('img')(({ theme }) => ({
  margin: theme.spacing(1, 'auto', 2),
  display: 'block',
}));

type LoginForm = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const initialValues = {
  username: '',
  password: '',
};

function Login() {
  const { loginRequest, login } = useAuth();
  const [currentUser] = useCurrentUserResource();

  const handleSubmit = useCallback(
    async (data: LoginForm) => {
      login(data);
    },
    [login]
  );

  const { search } = useLocation();

  const { form, setValue, setTouched, onSubmit } = useForm({ initialValues, schema, handleSubmit });

  if (currentUser) {
    return <Redirect to={(search && (queryString.parse(search)?.redirectTo as string)) || accessData.path} />;
  }

  return (
    <Wrapper maxWidth="xs">
      <Helmet>
        <title>{loginSection.title}</title>
      </Helmet>
      <Card component="form" onSubmit={onSubmit}>
        <CardContent>
          <Link to={home.path}>
            <Logo src={logo} alt="CDI – Centro Diagnostico Italiano" />
          </Link>
          <FormRow>
            <TextField
              label="Username"
              value={form.values.username}
              onChange={(value) => setValue(value, 'username')}
              onBlur={() => setTouched('username')}
              error={form.errors.username && form.touched.username}
              helperText={form.touched.username ? form.errors.username?.message : ''}
              disableClearable
              required
            />
          </FormRow>
          <FormRow>
            <TextField
              label="Password"
              type="password"
              value={form.values.password}
              onChange={(value) => setValue(value, 'password')}
              onBlur={() => setTouched('password')}
              error={form.errors.password && form.touched.password}
              helperText={form.touched.password ? form.errors.password?.message : ''}
              disableClearable
            />
          </FormRow>
          <FormRow>
            <ProgressButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={!form.isValid}
              inProgress={loginRequest.inProgress}
            >
              Login
            </ProgressButton>
          </FormRow>
          {loginRequest.error && <Alert severity="error">{loginRequest.error.message}</Alert>}
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default Login;
