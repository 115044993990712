import { RequestError } from '@top-solution/utils';

import { S3File } from '../../entities/S3File';

export const READ_USER_FILE_LIST_REQUEST = 'READ_USER_FILE_LIST_REQUEST';
export const READ_USER_FILE_LIST_SUCCESS = 'READ_USER_FILE_LIST_SUCCESS';
export const READ_USER_FILE_LIST_FAILURE = 'READ_USER_FILE_LIST_FAILURE';

export const DELETE_USER_FILE_LIST_REQUEST = 'DELETE_USER_FILE_LIST_REQUEST';
export const DELETE_USER_FILE_LIST_SUCCESS = 'DELETE_USER_FILE_LIST_SUCCESS';
export const DELETE_USER_FILE_LIST_FAILURE = 'DELETE_USER_FILE_LIST_FAILURE';

export const UPLOAD_USER_FILE_LIST_REQUEST = 'UPLOAD_USER_FILE_LIST_REQUEST';
export const UPLOAD_USER_FILE_LIST_PROGRESS = 'UPLOAD_USER_FILE_LIST_PROGRESS';
export const UPLOAD_USER_FILE_LIST_SUCCESS = 'UPLOAD_USER_FILE_LIST_SUCCESS';
export const UPLOAD_USER_FILE_LIST_FAILURE = 'UPLOAD_USER_FILE_LIST_FAILURE';
export const UPLOAD_USER_FILE_LIST_CLEAR = 'UPLOAD_USER_FILE_LIST_CLEAR';

const initialState = {
  list: [] as S3File[],
  requests: {
    readList: {
      inProgress: false,
      error: null as null | RequestError,
      lastUpdate: null as null | Date,
    },
    delete: {
      file: null as null | S3File,
      inProgress: false,
      error: null as null | RequestError,
    },
    upload: {
      progress: {} as { [filename: string]: number },
      completed: false,
      inProgress: false,
      error: null as null | RequestError,
    },
  },
};

export type UserFileState = typeof initialState;

export interface ReadUserFileListRequestAction {
  type: typeof READ_USER_FILE_LIST_REQUEST;
}
export interface ReadUserFileListSuccessAction {
  type: typeof READ_USER_FILE_LIST_SUCCESS;
  list: Array<S3File>;
}
export interface ReadUserFileListFailureAction {
  type: typeof READ_USER_FILE_LIST_FAILURE;
  error: RequestError;
}

export interface DeleteUserFileListRequestAction {
  type: typeof DELETE_USER_FILE_LIST_REQUEST;
  file: S3File;
}
export interface DeleteUserFileListSuccessAction {
  type: typeof DELETE_USER_FILE_LIST_SUCCESS;
}
export interface DeleteUserFileListFailureAction {
  type: typeof DELETE_USER_FILE_LIST_FAILURE;
  error: RequestError;
}

export interface UploadUserFileListRequestAction {
  type: typeof UPLOAD_USER_FILE_LIST_REQUEST;
  list: File[];
}
export interface UploadUserFileListProgressAction {
  type: typeof UPLOAD_USER_FILE_LIST_PROGRESS;
  filename: string;
  progress: number;
}
export interface UploadUserFileListSuccessAction {
  type: typeof UPLOAD_USER_FILE_LIST_SUCCESS;
}
export interface UploadUserFileListFailureAction {
  type: typeof UPLOAD_USER_FILE_LIST_FAILURE;
  error: RequestError;
}

export interface UploadUserFileListClearAction {
  type: typeof UPLOAD_USER_FILE_LIST_CLEAR;
}

type ActionType =
  | ReadUserFileListRequestAction
  | ReadUserFileListSuccessAction
  | ReadUserFileListFailureAction
  | DeleteUserFileListRequestAction
  | DeleteUserFileListSuccessAction
  | DeleteUserFileListFailureAction
  | UploadUserFileListRequestAction
  | UploadUserFileListProgressAction
  | UploadUserFileListSuccessAction
  | UploadUserFileListFailureAction
  | UploadUserFileListClearAction;

export default function userFileReducer(state = initialState, action: ActionType): UserFileState {
  switch (action.type) {
    case READ_USER_FILE_LIST_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_USER_FILE_LIST_SUCCESS: {
      return {
        ...state,
        list: action.list,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: null,
            lastUpdate: new Date(),
          },
        },
      };
    }
    case READ_USER_FILE_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case DELETE_USER_FILE_LIST_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            file: action.file,
            inProgress: true,
          },
        },
      };
    case DELETE_USER_FILE_LIST_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: false,
            error: null,
          },
        },
      };
    }
    case DELETE_USER_FILE_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPLOAD_USER_FILE_LIST_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          upload: {
            ...state.requests.upload,
            inProgress: true,
            completed: false,
            progress: action.list.reduce(
              (progress, { name }) => ({
                ...progress,
                [name]: 0,
              }),
              {}
            ),
          },
        },
      };
    }
    case UPLOAD_USER_FILE_LIST_PROGRESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          upload: {
            ...state.requests.upload,
            inProgress: true,
            progress: {
              ...state.requests.upload.progress,
              [action.filename]: action.progress,
            },
          },
        },
      };
    }
    case UPLOAD_USER_FILE_LIST_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          upload: {
            ...state.requests.upload,
            completed: true,
            inProgress: false,
            error: null,
          },
        },
      };
    }
    case UPLOAD_USER_FILE_LIST_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          upload: {
            ...state.requests.upload,
            inProgress: false,
            error: action.error,
          },
        },
      };
    }
    case UPLOAD_USER_FILE_LIST_CLEAR: {
      return {
        ...state,
        requests: {
          ...state.requests,
          upload: {
            ...initialState.requests.upload,
          },
        },
      };
    }
    default:
      return state;
  }
}
