import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { useCurrentUserResource } from '../hooks/store/useAuth';
import { login } from '../sections';

function AuthenticatedRoute(props: RouteProps) {
  const [currentUser, request] = useCurrentUserResource();
  const { pathname } = useLocation();

  if (!request.error && !request.lastUpdate) {
    return (
      <Backdrop open style={{ color: 'white' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  if (!currentUser) {
    return <Redirect to={queryString.stringifyUrl({ url: login.path, query: { redirectTo: pathname } })} />;
  }
  return <Route {...props}>{props.children}</Route>;
}

export default AuthenticatedRoute;
