import React, { useMemo, useState } from 'react';
import { Avatar, Chip, styled } from '@material-ui/core';

import { HiddenColumns, SearchFilter } from '../../entities';
import { FilterPlus as AddFilterIcon } from 'mdi-material-ui';
import AddFilterDialog from './AddFilterDialog';
import HideColumnsDialog from './HideColumnsDialog';

const FilterChip = styled(Chip)({
  marginBottom: 2,
  marginRight: 2,
});

type SearchSettingsProps = {
  filters: SearchFilter[];
  setFilters: (filter: SearchFilter[]) => void;
  hiddenColumns: HiddenColumns;
  setHiddenColumns: (hiddenColumns: HiddenColumns) => void;
};

function SearchSettings(props: SearchSettingsProps) {
  const { filters, setFilters, hiddenColumns } = props;
  const [addFilterDialogOpen, setAddFilterDialogOpen] = useState(false);
  const [hideColumnsDialogOpen, setHideColumnsDialogOpen] = useState(false);
  const totalHiddenColumns = useMemo(
    () => Object.values(hiddenColumns).reduce((count, hidden) => count + (hidden ? 1 : 0), 0),
    [hiddenColumns]
  );

  const handleDelete = (index: number) => {
    setFilters([...filters.slice(0, index), ...filters.slice(index + 1, filters.length)]);
  };

  const handleAddFilterDialogClose = (filter?: SearchFilter) => {
    if (filter) {
      setFilters([...filters, filter]);
    }
    setAddFilterDialogOpen(false);
  };

  return (
    <div>
      <FilterChip
        avatar={<Avatar>{totalHiddenColumns}</Avatar>}
        label="Hidden columns"
        onClick={() => setHideColumnsDialogOpen(true)}
        color={totalHiddenColumns > 0 ? 'secondary' : undefined}
      />
      <FilterChip label="Add filter" icon={<AddFilterIcon />} onClick={() => setAddFilterDialogOpen(true)} />
      {filters.map(({ column, value, operator }, index) => (
        <FilterChip
          variant="outlined"
          color="primary"
          key={index}
          label={`${column.headerName} ${operator.label} ${
            typeof column.type === 'object' ? column.type[Number(value)] : value
          }`}
          onDelete={() => handleDelete(index)}
        />
      ))}
      <AddFilterDialog open={addFilterDialogOpen} onClose={handleAddFilterDialogClose} />
      <HideColumnsDialog open={hideColumnsDialogOpen} onClose={() => setHideColumnsDialogOpen(false)} />
    </div>
  );
}

export default SearchSettings;
