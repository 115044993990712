import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogProps,
  FormControlLabel,
  Switch,
  styled,
} from '@material-ui/core';

import { Spacer } from '../../components';
import columns from './columns';
import { useSettings } from '../../hooks/store/useSettings';

const ContentWrapper = styled(DialogContent)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  display: 'flex',
  flexFlow: 'row wrap',

  '& > .MuiFormControlLabel-root': {
    margin: theme.spacing(0, 1, 1),
    flex: `1 0 100%`,
    maxWidth: theme.breakpoints.values.md / 3 - 16,
  },
}));

interface HideColumnsDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
}

function HideColumnsDialog(props: HideColumnsDialogProps) {
  const { onClose, ...otherProps } = props;
  const { hiddenColumns, setHiddenColumns } = useSettings();
  const [hideColumn, setHideColumns] = useState({ ...hiddenColumns });

  const handleColumnDisplayChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setHideColumns({
      ...hideColumn,
      [event.target.name]: !checked,
    });
  };

  const handleSave = () => {
    setHiddenColumns(hideColumn);
    onClose();
  };

  return (
    <Dialog disableBackdropClick fullWidth maxWidth="md" {...otherProps}>
      <DialogTitle>Shown columns</DialogTitle>
      <ContentWrapper>
        {columns.map((column) => (
          <FormControlLabel
            key={column.field}
            control={
              <Switch checked={!hideColumn[column.field]} onChange={handleColumnDisplayChange} name={column.field} />
            }
            label={column.headerName}
          />
        ))}
      </ContentWrapper>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Spacer />
        <Button type="submit" variant="contained" color="secondary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HideColumnsDialog;
